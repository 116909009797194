import { SvgIcon, SvgIconProps } from '@mui/material';

export default function GeneFinding(props: SvgIconProps) {
  return (
    <SvgIcon width='10' height='16' viewBox='0 0 10 16' {...props}>
      <path
        d='M1.5 1V15M5.49996 10.7941L1.69999 13.5685M5.49996 3.79407L1.69999 6.56848M8.70007 10C8.70007 10.8284 8.0285 11.5 7.20007 11.5C6.37165 11.5 5.70007 10.8284 5.70007 10C5.70007 9.17157 6.37165 8.5 7.20007 8.5C8.0285 8.5 8.70007 9.17157 8.70007 10ZM8.70007 3C8.70007 3.82843 8.0285 4.5 7.20007 4.5C6.37165 4.5 5.70007 3.82843 5.70007 3C5.70007 2.17157 6.37165 1.5 7.20007 1.5C8.0285 1.5 8.70007 2.17157 8.70007 3Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
