import { CustomComponent } from 'styles/type-extension';
import { primary } from '../../palette/primary';
import { secondary } from '../../palette/secondary';

export const MuiButtonBase: CustomComponent<'MuiButtonBase'> = {
  defaultProps: {
    color: 'primary',
  },
};

export const MuiButton: CustomComponent<'MuiButton'> = {
  styleOverrides: {
    root: {
      paddingTop: '10px',
      paddingBottom: '10px',
      '&.Mui-disabled': {
        borderColor: primary[100],
        backgroundColor: primary[100],
        color: primary[400],
      },
      '&.Mui-focusVisible ': {
        borderColor: primary[900],
        boxShadow: `0px 0px 5px 3px ${secondary[300]}`,
      },
    },
  },
};
