import { CustomComponent } from 'styles/type-extension';
import { secondary } from '../../palette/secondary';

export const MuiLink: CustomComponent<'MuiLink'> = {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      color: secondary[500],
    },
  },
};
