import { SvgIcon, SvgIconProps } from '@mui/material';

export default function WavesIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M2.5 4.49997C2.5 4.49997 5 2.49997 8 4.49997C11 6.49997 13.5 4.49997 13.5 4.49997'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M2.5 7.99997C2.5 7.99997 5 5.99997 8 7.99997C11 9.99997 13.5 7.99997 13.5 7.99997'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M2.5 11.5C2.5 11.5 5 9.49997 8 11.5C11 13.5 13.5 11.5 13.5 11.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
