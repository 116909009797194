import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Funnel(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M28.5624 6.18751C28.4068 5.83347 28.1512 5.53255 27.827 5.32162C27.5028 5.11068 27.1242 4.99892 26.7374 5.00001H5.26243C4.87579 5.00312 4.49823 5.11754 4.17491 5.32959C3.85159 5.54164 3.5962 5.84234 3.43929 6.19573C3.28237 6.54911 3.23058 6.94021 3.2901 7.32226C3.34963 7.7043 3.51795 8.06111 3.77493 8.35001L11.9999 17.3875V27.1375C11.9993 27.5003 12.098 27.8563 12.2853 28.167C12.4726 28.4777 12.7413 28.7312 13.0624 28.9C13.3515 29.0528 13.673 29.1342 13.9999 29.1375C14.396 29.1376 14.7832 29.0202 15.1124 28.8L19.1124 26.125C19.3864 25.943 19.6109 25.6958 19.7658 25.4056C19.9206 25.1155 20.0011 24.7914 19.9999 24.4625V17.3875L28.2249 8.35001C28.4867 8.06215 28.6582 7.70381 28.7182 7.3194C28.7782 6.93499 28.724 6.54143 28.5624 6.18751Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
