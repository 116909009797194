import { createCustomerJourney, CustomerJourneyData, fetchAllAdmin } from '../../../services/admin/projects';
import { useMutation, useQuery } from '@tanstack/react-query';
import useInvalidation from '../../useInvalidation';

const KEY = 'projects';

export function useProjectsAdmin(oid?: string) {
    return useQuery([KEY], () => fetchAllAdmin(oid));
}

export const useCustomerJourneyProjectAdmin = () => {
    const invalidate = useInvalidation(KEY);
    return useMutation((data: CustomerJourneyData) => createCustomerJourney(data.oid, data.projectName), {
        onSuccess: () => {
            return invalidate()
        }
    })
}