import { useMutation, useQuery } from '@tanstack/react-query';

import {
  copyItem,
  fetchOne,
  fetchSignedURL,
  removeItem,
  renameItem,
} from '../../services/storage';
import { CopyVaultItemParams, FetchVaultParams } from '../../types';

const MS_REFRESH = 30000;
const KEY = 'storage';

export function useStorage(
  oid: string,
  pid?: string,
  params?: FetchVaultParams,
) {
  const { shouldRefresh, ...rest } = params || {};

  return useQuery(
    [KEY, oid, pid, rest],
    () => {
      if (pid) {
        return fetchOne(oid, pid, rest);
      }
    },
    {
      refetchInterval: shouldRefresh && MS_REFRESH,
      enabled: !!pid,
    },
  );
}

export function useRemoveStorageItem(oid: string, pid: string) {
  return useMutation((item: string) => removeItem(oid, pid, item));
}

export function useRenameStorageItem(oid: string, pid: string) {
  return useMutation(
    ({ oldPath, newPath }: { oldPath: string; newPath: string }) =>
      renameItem(oid, pid, oldPath, newPath),
  );
}

export function useCopyStorageItem(oid: string, pid: string) {
  return useMutation((params: CopyVaultItemParams) =>
    copyItem(oid, pid, params),
  );
}

export function useFetchSignedURL() {
  return useMutation(fetchSignedURL);
}
