export const CHART_WIDTH = 540;
export const CHART_HEIGHT = 460;

export const DEFAULT_MARGIN = '24px';
export const DEFAULT_PADDING = '12px';
export const HALF_PADDING = '6px';

// can be used to adujst buttons to
// have the same height as inputs
export const INPUT_HEIGHT = '56px';

export const DEFAULT_BORDER_RADIUS = '4px';

export const NAV_DRAWER_WIDTH = 240;
// a bit arbitrary but it makes the icons centered
// without having to have special logic on
// the nav item with or without text.
export const NAV_DRAWER_MINIED = 58;

// special key used for navigating to review page of scenario step-builder
export const REVIEW_KEY = 'review';

export const TABLE_COL_MIN_WIDTH = 125;
export const TABLE_COL_MIN_WIDTH_LARGE = 300;
