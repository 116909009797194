import { ExtendedTypography } from 'styles/type-extension';
import { primary } from './palette/primary';

const SEMI_BOLD_FONT_WEIGHT = 600;
const DEFAULT_FONT_COLOR = primary[900];
const BODY_FONT_COLOR = primary[700];
const DEFAULT_FONT_COLOR_DARK = primary[50];
const H_FONT_FAMILY = 'Roobert';
const CONTENT_FONT_FAMILY = 'Inter';
const CODE_FONT_FAMILY = 'Fira Code';

function createTypography(
  hColor: string | undefined,
  bodyColor: string | undefined,
): ExtendedTypography {
  const typography: ExtendedTypography = {
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1.2,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    h5: {
      fontSize: '0.875rem',
      lineHeight: 24 / 14,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    h6: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: H_FONT_FAMILY,
      fontVariantLigatures: 'none',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    body3: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: 400,
      color: bodyColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    caption1: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: bodyColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    caption2: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: bodyColor,
      textTransform: 'uppercase',
      fontFamily: CONTENT_FONT_FAMILY,
    },
    button1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      textTransform: 'none',
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    button2: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      textTransform: 'none',
      fontWeight: SEMI_BOLD_FONT_WEIGHT,
      color: hColor,
      fontFamily: CONTENT_FONT_FAMILY,
    },
    code1: {
      fontSize: '0.875rem',
      lineHeight: 20 / 14,
      fontWeight: 400,
      color: hColor,
      fontFamily: CODE_FONT_FAMILY,
    },
    code2: {
      fontSize: '0.75rem',
      lineHeight: 4 / 3,
      fontWeight: 400,
      color: hColor,
      fontFamily: CODE_FONT_FAMILY,
    },
  };
  typography.caption = typography.caption1;
  typography.button = typography.button2;
  return typography;
}

const typography: ExtendedTypography = createTypography(
  DEFAULT_FONT_COLOR,
  BODY_FONT_COLOR,
);
export const typographyDark: ExtendedTypography = createTypography(
  DEFAULT_FONT_COLOR_DARK,
  DEFAULT_FONT_COLOR_DARK,
);

export default typography;
