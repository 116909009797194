import { useQuery } from '@tanstack/react-query';

import { fetchAll, fetchOne } from '../../services/toolbox';

const KEY = 'toolbox';

export function useToolbox() {
  return useQuery([KEY], () => fetchAll());
}

export function useToolboxTool(params: Parameters<typeof fetchOne>[0]) {
  return useQuery([KEY, params], () => fetchOne(params));
}
