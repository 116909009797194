import { ToolboxConditional } from './conditionals';
import { ToolboxSchemaOutputField } from './fields';
import { ToolboxInputs } from './inputs';
import { ToolboxUI } from './ui';
export * from './fields';
export * from './conditionals';
export * from './groups';
export * from './values';
export * from './inputs';
export * from './ui';

export interface Tool {
  id: string;
  title: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  versions?: ToolboxSchema[];
  description: string;
}

export type Tools = Tool[];

export enum ToolboxCategory {
  ALL = 'All',
  NUCLEIC_ACID_UTIL = 'Nucleic Acid Util',
}

export interface ToolboxSchema {
  id: string;
  description: string;
  version: string;
  changelog: string;
  categories?: ToolboxCategory[];
  createdAt: string;
  updatedAt: string;
  inputs: ToolboxInputs;
  ui: ToolboxUI;
  outputs: Record<string, ToolboxSchemaOutputField>;
  conditionals?: ToolboxConditional[];
}
