import { PaletteColor } from '@mui/material';

export const tertiary: { [color: string]: Partial<PaletteColor> } = {
  green: {
    dark: '#4B780D',
    main: '#81BC2F',
    light: '#E9F9D2',
  },
  teal: {
    dark: '#066B49',
    main: '#0AC286',
    light: '#D8FDF1',
  },
  blue: {
    dark: '#0078A1',
    main: '#2EB7E5',
    light: '#E4F8FF',
  },
  indigo: {
    dark: '#2222C3',
    main: '#3E3EDD',
    light: '#E9E9FB',
  },
  purple: {
    dark: '#7507DF',
    main: '#9B39F9',
    light: '#F0E1FE',
  },
  violet: {
    dark: '#9D2DB4',
    main: '#C25CD6',
    light: '#F6E7F9',
  },
  pink: {
    dark: '#B21A57',
    main: '#E93D82',
    light: '#FCE5F0',
  },
  orange: {
    dark: '#BD4B00',
    main: '#F76808',
    light: '#FFE7D7',
  },
  infoAlt: {
    main: '#759EF0',
    light: '#E6EAFE',
    dark: '#3D55D2',
  },
  errorAlt: {
    main: '#D60000',
    light: '#FCE3E3',
    dark: '#AD0000',
  },
  warningAlt: {
    main: '#FFBB33',
    light: '#FFEECC',
    dark: '#805500',
  },
  successAlt: {
    main: '#85E0C2',
    light: '#E3FCE7',
    dark: '#006611',
  },
  primaryAlt: {
    main: '#59A6A6',
    light: '#F1F9F9',
    dark: '#067979',
  },
  greenAlt: {
    main: '#CCFF33',
    light: '#EEFFB3',
    dark: '#33470A',
  },
};
