import axios, { AxiosRequestConfig } from 'axios';

export const api = axios.create({});

type InitParams = {
  getToken: () => Promise<string | undefined>;
  baseURL: string;
  onError?: (error: unknown) => void;
};

export function init({ baseURL, getToken, onError }: InitParams) {
  api.defaults.baseURL = baseURL;

  api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = await getToken();

    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  if (onError) {
    api.interceptors.response.use(undefined, (error: unknown) => {
      onError(error);
      throw error;
    });
  }
}
