import { getAuthToken } from '@/utils/auth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config';

export async function requestInterceptor(config: AxiosRequestConfig) {
  const token = await getAuthToken();

  if (token) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export const api = axios.create({
  baseURL: `${config.apiUrl}/api/${config.banthaApiVersion}`,
});

export async function apiBatch(requests: Promise<AxiosResponse>[]) {
  return Promise.all(requests);
}

api.interceptors.request.use(requestInterceptor);

export const apiWithDataResponse = axios.create({
  baseURL: `${config.apiUrl}/api/${config.banthaApiVersion}`,
});

apiWithDataResponse.interceptors.request.use(requestInterceptor);

apiWithDataResponse.interceptors.response.use(response => {
  return response.data;
});
