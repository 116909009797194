import { ToolRunResponse } from '../../types/tool-run';
import { ToolboxParamValues } from '../../types/toolbox';
import { api } from '../../utils/api';

export type CreateData = {
  oid: string;
  pid: string;
  inputs: ToolboxParamValues;
  toolVersionID: string;
};

export function fetchAll({ oid, pid }: { oid: string; pid: string }) {
  return api
    .get<ToolRunResponse[]>(`/orgs/${oid}/${pid}/tool-runs`)
    .then(({ data }) => data);
}

export function fetchOne({
  oid,
  pid,
  id,
}: {
  oid: string;
  pid: string;
  id: string;
}) {
  return api
    .get<ToolRunResponse>(`/orgs/${oid}/${pid}/tool-runs/${id}`) // name of the endpoint is TBD
    .then(({ data }) => data);
}

export function create({ oid, pid, ...data }: CreateData) {
  const url = `/orgs/${oid}/${pid}/tool-runs`;
  return api.post<ToolRunResponse>(url, data).then(resp => resp.data);
}
