import { useQuery } from '@tanstack/react-query';

import { fetchAll, fetchOne } from '../../services/workflows';

const KEY = 'workflows';

export function useWorkflows(oid: string, pid: string) {
  return useQuery([KEY, { oid, pid }], () => fetchAll(oid, pid));
}

export function useWorkflow(params: Parameters<typeof fetchOne>[0]) {
  return useQuery([KEY, params], () => fetchOne(params));
}
