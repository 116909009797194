import { useAnalytics } from '@/utils/analytics';
import { Auth, getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { app } from 'lib/firebase';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { setUser as setLoggingUser } from '@/utils/logging';

export type AuthUser = User;

type AuthContextType = {
  auth: Auth;
  user: AuthUser | null;
  loading: boolean;
};

const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const auth = getAuth(app);
  const analytics = useAnalytics();
  const ldClient = useLDClient();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | null>(null);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async user => {
      setLoading(false);
      setUser(user);
    });

    return () => {
      listener();
    };
  }, [auth]);

  useEffect(() => {
    if (user) {
      const { uid, email, displayName } = user;

      analytics.identify(uid, {
        email,
        name: displayName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user && user.email) {
      const { uid, email } = user;

      setLoggingUser({ id: uid, email });
    } else {
      setLoggingUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (user && ldClient) {
      const { uid, email, displayName } = user;

      ldClient.identify({
        key: uid,
        name: displayName || undefined, // LaunchDarkly wants a string or undefined, not a null
        email: email || undefined,
      });
    }
  }, [user, ldClient]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
