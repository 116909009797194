import * as snippet from '@segment/snippet';
import Script from 'next/script';
import { ReactNode, useMemo } from 'react';

export const ANALYTICS_EVENT = {
  LOGOUT: 'logout',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  START_WORKFLOW_RUN: 'start-workflow-run',
  SUBMIT_WORKFLOW_RUN: 'submit-workflow-run',
};

type Props = {
  apiKey?: string;
  children: ReactNode;
};

export function AnalyticsProvider({ children, apiKey }: Props) {
  const scriptSnippet = useMemo(() => {
    const options = {
      apiKey,
      page: false,
    };

    return process.env.NODE_ENV === 'development'
      ? snippet.max(options)
      : snippet.min(options);
  }, [apiKey]);

  return (
    <>
      {scriptSnippet && (
        <Script
          id='segment-script'
          dangerouslySetInnerHTML={{
            __html: scriptSnippet,
          }}
        />
      )}
      {children}
    </>
  );
}

export function useAnalytics() {
  function page(name: string, options?: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.page(name, options);
    }
  }

  function track(name: string, properties?: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.track(name, properties);
    }
  }

  function identify(id: string, traits: Record<string, unknown>) {
    if (window && window.analytics) {
      window.analytics.identify(id, traits);
    }
  }

  function reset() {
    if (window && window.analytics) {
      window.analytics.reset();
    }
  }

  return {
    page,
    track,
    identify,
    reset,
  };
}
