import { SvgIcon, SvgIconProps } from '@mui/material';

export default function PlusCircle(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M5.5 7.25C5.08579 7.25 4.75 7.58579 4.75 8C4.75 8.41421 5.08579 8.75 5.5 8.75V7.25ZM10.5 8.75C10.9142 8.75 11.25 8.41421 11.25 8C11.25 7.58579 10.9142 7.25 10.5 7.25V8.75ZM8.75 5.5C8.75 5.08579 8.41421 4.75 8 4.75C7.58579 4.75 7.25 5.08579 7.25 5.5H8.75ZM7.25 10.5C7.25 10.9142 7.58579 11.25 8 11.25C8.41421 11.25 8.75 10.9142 8.75 10.5H7.25ZM13.25 8C13.25 10.8995 10.8995 13.25 8 13.25V14.75C11.7279 14.75 14.75 11.7279 14.75 8H13.25ZM8 13.25C5.10051 13.25 2.75 10.8995 2.75 8H1.25C1.25 11.7279 4.27208 14.75 8 14.75V13.25ZM2.75 8C2.75 5.10051 5.10051 2.75 8 2.75V1.25C4.27208 1.25 1.25 4.27208 1.25 8H2.75ZM8 2.75C10.8995 2.75 13.25 5.10051 13.25 8H14.75C14.75 4.27208 11.7279 1.25 8 1.25V2.75ZM5.5 8.75H10.5V7.25H5.5V8.75ZM7.25 5.5V10.5H8.75V5.5H7.25Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
