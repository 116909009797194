import config from 'config';
import { getApp, getApps, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  authDomain: config.firebaseAuthDomain,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
  messagingSenderId: config.firebaseStorageBucket,
  appId: config.firebaseAppId,
  measurementId: config.firebaseMeasurementId,
};

export const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
