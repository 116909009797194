import { ToolboxFileField, ToolboxUI } from './toolbox';

export enum ToolRunStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Running = 'RUNNING', // not used for now
  Failed = 'FAILED',
}

export type ToolRunResults = Record<string, ToolRunResult>;

export interface ToolRunFileResult {
  path: string;
  size: number;
}

export interface ToolRunResult {
  paths: ToolRunFileResult[];
  schema: ToolboxFileField;
}
export interface ToolRunResponse {
  id: string;
  status: ToolRunStatus;
  createdAt: string;
  updatedAt: string;
  startedAt: string;
  completedAt: string;
  results: ToolRunResults;
  errorMessage?: string;
  outputPath: string;
  commandOutputPath: string;
  tool: {
    slug: string;
    name: string;
    description: string;
    version: string;
    ui: ToolboxUI;
  };
  inputs: Record<string, string>;
  actor: {
    Name: string;
    id: string;
    type: string;
  };
}
