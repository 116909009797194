import { QueryParams } from '@/utils/routes';
import { useEffect, useState } from 'react';

type StoredIdsType = {
  storedOrgId?: string;
  storedProjectId?: string;
};

export function useOrgProjectLocalStorage() {
  const [storedIds, setStoredIds] = useState<StoredIdsType>(() => ({
    storedOrgId: localStorage.getItem(QueryParams.orgId) || undefined,
    storedProjectId: localStorage.getItem(QueryParams.projId) || undefined,
  }));

  /**
   * Manages the localStorage when orgId or pid changes.
   */
  useEffect(() => {
    const { storedOrgId, storedProjectId } = storedIds;
    if (storedOrgId && storedProjectId) {
      // both ids are present: save both
      localStorage.setItem(QueryParams.orgId, storedOrgId);
      localStorage.setItem(QueryParams.projId, storedProjectId);
    } else if (storedOrgId) {
      // only the orgId changed. Clear pid because it's
      // now out of sync
      localStorage.setItem(QueryParams.orgId, storedOrgId);
      localStorage.removeItem(QueryParams.projId);
    } else {
      // clear both
      localStorage.removeItem(QueryParams.orgId);
      localStorage.removeItem(QueryParams.projId);
    }
  }, [storedIds]);

  function saveOrgId(orgId: string) {
    setStoredIds(() => ({ storedOrgId: orgId }));
  }

  function saveProjectId(orgId: string, projectId: string) {
    setStoredIds(() => ({
      storedOrgId: orgId,
      storedProjectId: projectId,
    }));
  }

  function clear() {
    setStoredIds(() => ({}));
  }

  return {
    storedOrgId: storedIds.storedOrgId,
    storedProjectId: storedIds.storedProjectId,
    saveOrgId,
    saveProjectId,
    clear,
  };
}
