import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ArrowLeft(props: SvgIconProps) {
  return (
    <SvgIcon width='12' height='12' viewBox='0 0 12 12' {...props}>
      <path
        d='M10.125 6H1.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M5.25 2.625L1.875 6L5.25 9.375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
