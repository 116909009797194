import { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  MemberData,
  CreateData,
  addMembers,
} from '../../services/organizations';
import {
  addMember,
  create,
  fetchAll,
  fetchOne,
  remove,
  removeMember,
  updateMember,
} from '../../services/organizations';
import { useProjects } from '../projects';
import useInvalidation from '../useInvalidation';

const KEY = 'organizations';

export function useOrganizations() {
  return useQuery([KEY], fetchAll, { retry: 1 });
}

export function useOrganization(oid: string) {
  return useQuery([KEY, oid], () => fetchOne(oid));
}

export function useRemoveOrganizationMember(oid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation(({ email, params }: 
    { email: string, params?: { deleteProjectMembership?: boolean } }) => 
      removeMember(oid, email, params), {
        onSuccess: () => {
          return invalidate();
        },
  });
}

export function useUpdateOrganizationMember(oid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData) => updateMember(oid, data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useAddOrganization() {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: CreateData) => create(data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useRemoveOrganization() {
  const invalidate = useInvalidation(KEY);

  return useMutation((oid: string) => remove(oid), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useAddOrganizationMember(oid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData) => addMember(oid, data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useAddOrganizationMembers(oid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData[]) => addMembers(oid, data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

/**
 * Returns a list of all the organizations a user is a member
 * of and organizations whose projects they belong to, but are
 * not a member of.
 */

export function useAllOrganizations() {
  const projectsQuery = useProjects();
  const organizationsQuery = useOrganizations();

  const allOrganizations = useMemo(() => {
    if (organizationsQuery.data && projectsQuery.data) {
      return projectsQuery.data.reduce(
        (acc, project) => {
          if (!acc.find(o => o.id === project.organization.id)) {
            acc.push(project.organization);
          }
          return acc;
        },
        [...organizationsQuery.data],
      );
    }
  }, [organizationsQuery.data, projectsQuery.data]);
  return {
    allOrganizations,
    isLoading: organizationsQuery.isLoading || projectsQuery.isLoading,
  };
}
