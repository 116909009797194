import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ArrowsLeftRight(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
      <path
        d='M26.7125 22.7125L22.7125 26.7125C22.5206 26.896 22.2656 26.9989 22 27C21.8673 26.9994 21.7359 26.974 21.6125 26.925C21.4305 26.8485 21.2752 26.7198 21.1662 26.5552C21.0572 26.3906 20.9994 26.1974 21 26V23H6.00001C5.73479 23 5.48044 22.8947 5.2929 22.7071C5.10537 22.5196 5.00001 22.2652 5.00001 22C5.00001 21.7348 5.10537 21.4804 5.2929 21.2929C5.48044 21.1054 5.73479 21 6.00001 21H21V18C20.9994 17.8026 21.0572 17.6094 21.1662 17.4448C21.2752 17.2802 21.4305 17.1515 21.6125 17.075C21.7972 17.003 21.9986 16.9847 22.1932 17.0223C22.3879 17.0599 22.5679 17.1519 22.7125 17.2875L26.7125 21.2875C26.9003 21.4771 27.0056 21.7332 27.0056 22C27.0056 22.2669 26.9003 22.5229 26.7125 22.7125ZM9.28751 14.7125C9.47947 14.896 9.73447 14.9989 10 15C10.1327 14.9994 10.2642 14.974 10.3875 14.925C10.5695 14.8485 10.7248 14.7198 10.8338 14.5552C10.9428 14.3906 11.0006 14.1974 11 14V11H26C26.2652 11 26.5196 10.8947 26.7071 10.7071C26.8947 10.5196 27 10.2652 27 10C27 9.7348 26.8947 9.48044 26.7071 9.29291C26.5196 9.10537 26.2652 9.00001 26 9.00001H11V6.00001C11.0006 5.80259 10.9428 5.6094 10.8338 5.44479C10.7248 5.28018 10.5695 5.15151 10.3875 5.07501C10.2028 5.00297 10.0015 4.98469 9.8068 5.0223C9.61214 5.0599 9.4321 5.15185 9.28751 5.28751L5.28751 9.28751C5.09973 9.47711 4.99438 9.73317 4.99438 10C4.99438 10.2669 5.09973 10.5229 5.28751 10.7125L9.28751 14.7125Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
