import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Calculator(props: SvgIconProps) {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M16.5 6H7.5V10.5H16.5V6Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M19.5 20.25V3.75C19.5 3.33579 19.1642 3 18.75 3L5.25 3C4.83579 3 4.5 3.33579 4.5 3.75L4.5 20.25C4.5 20.6642 4.83579 21 5.25 21H18.75C19.1642 21 19.5 20.6642 19.5 20.25Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.375 13.875C8.375 13.944 8.31904 14 8.25 14C8.18096 14 8.125 13.944 8.125 13.875C8.125 13.806 8.18096 13.75 8.25 13.75C8.31904 13.75 8.375 13.806 8.375 13.875Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M12.125 13.875C12.125 13.944 12.069 14 12 14C11.931 14 11.875 13.944 11.875 13.875C11.875 13.806 11.931 13.75 12 13.75C12.069 13.75 12.125 13.806 12.125 13.875Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M15.875 13.875C15.875 13.944 15.819 14 15.75 14C15.681 14 15.625 13.944 15.625 13.875C15.625 13.806 15.681 13.75 15.75 13.75C15.819 13.75 15.875 13.806 15.875 13.875Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M8.25 18.75C8.87132 18.75 9.375 18.2463 9.375 17.625C9.375 17.0037 8.87132 16.5 8.25 16.5C7.62868 16.5 7.125 17.0037 7.125 17.625C7.125 18.2463 7.62868 18.75 8.25 18.75Z'
        fill='currentColor'
      />
      <path
        d='M12 18.75C12.6213 18.75 13.125 18.2463 13.125 17.625C13.125 17.0037 12.6213 16.5 12 16.5C11.3787 16.5 10.875 17.0037 10.875 17.625C10.875 18.2463 11.3787 18.75 12 18.75Z'
        fill='currentColor'
      />
      <path
        d='M15.75 18.75C16.3713 18.75 16.875 18.2463 16.875 17.625C16.875 17.0037 16.3713 16.5 15.75 16.5C15.1287 16.5 14.625 17.0037 14.625 17.625C14.625 18.2463 15.1287 18.75 15.75 18.75Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
