import { SvgIcon, SvgIconProps } from '@mui/material';

export default function UserSwitch(props: SvgIconProps) {
  return (
    <SvgIcon width='18' height='16' viewBox='0 0 18 16' {...props}>
      <path
        d='M9 9.99999C10.3807 9.99999 11.5 8.8807 11.5 7.49999C11.5 6.11928 10.3807 4.99999 9 4.99999C7.61929 4.99999 6.5 6.11928 6.5 7.49999C6.5 8.8807 7.61929 9.99999 9 9.99999ZM9 9.99999C8.16899 9.99999 7.3542 10.2297 6.64596 10.6644C5.93772 11.0992 5.36369 11.7215 4.9875 12.4625M9 9.99999C9.83101 9.99999 10.6458 10.2297 11.354 10.6644C12.0623 11.0992 12.6363 11.7215 13.0125 12.4625M13.5 7.99999L15 9.49999M15 9.49999L16.5 7.99999M15 9.49999V7.99999C15.0014 6.70366 14.5829 5.44173 13.8072 4.4031C13.0315 3.36447 11.9403 2.60501 10.6969 2.23839C9.45347 1.87177 8.12476 1.91771 6.90965 2.36935C5.69453 2.82098 4.65837 3.65401 3.95625 4.74374M1.5 7.99999L3 6.49999M3 6.49999L4.5 7.99999M3 6.49999V7.99999C2.99858 9.29632 3.41705 10.5582 4.19276 11.5969C4.96848 12.6355 6.05971 13.395 7.30312 13.7616C8.54653 14.1282 9.87524 14.0823 11.0904 13.6306C12.3055 13.179 13.3416 12.346 14.0438 11.2562'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
