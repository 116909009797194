import { useQuery, useMutation } from '@tanstack/react-query';

import { fetchAll, fetchOne, remove } from '../../services/workflow-runs';
import useInvalidation from '../useInvalidation';

const KEY = 'workflow-runs';

export function useWorkflowRuns(oid: string, pid: string) {
  return useQuery([KEY, { oid, pid }], () => fetchAll(oid, pid));
}

export function useWorkflowRun(id: string) {
  return useQuery([KEY, id], () => fetchOne(id));
}

export function useRemoveWorkflowRun(oid: string, pid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation(
    ({ id, params }: { id: string; params?: { removeOutput?: boolean } }) =>
      remove(id, params),
    {
      onSuccess: () => {
        return invalidate({ oid, pid });
      },
    },
  );
}
