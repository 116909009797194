import { apiWithDataResponse } from '@/api/api-instance';
import { AuthContextProvider } from '@/context/auth';
import { NavigationParamsContextProvider } from '@/context/navigationContext';
import { AnalyticsProvider } from '@/utils/analytics';
import createEmotionCache from '@/utils/createEmotionCache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import config from 'config';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import 'regenerator-runtime/runtime.js';
import { themeFormbio } from 'styles/theme';
import { SWRConfig } from 'swr';
import { init as initAPI } from '@formbio/api';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '../data/mock';
import '../styles/global.css';
import { getAuth, getIdToken } from 'firebase/auth';
import { app } from 'lib/firebase';
import { NotificationContextProvider } from '@/context/notificationContext';
import { captureError } from '@/utils/logging';
import { LinearProgress } from '@mui/material';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';

// MUI X DataGrid Pro
LicenseInfo.setLicenseKey(config.dataGridProLicense || '');

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const swrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: undefined,
  fetcher: apiWithDataResponse.get,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: 'always',
      staleTime: 60 * 1000, // 1 Minute
    },
  },
});

initAPI({
  getToken: async () => {
    const auth = getAuth(app);

    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }
  },
  baseURL: `${config.apiUrl}/api/${config.banthaApiVersion}`,
  onError: (error: unknown) => {
    captureError({ error });
  },
});

function FormBio(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { ready } = useLaunchDarkly();
  if (!ready) {
    return (
      <ThemeProvider theme={themeFormbio}>
        <LinearProgress />
      </ThemeProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SWRConfig value={swrOptions}>
        <AuthContextProvider>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={themeFormbio}>
              <AnalyticsProvider apiKey={config.segmentAnalyticsApiKey}>
                <NavigationParamsContextProvider>
                  <NotificationContextProvider>
                    <CssBaseline />
                    <Component {...pageProps} />
                  </NotificationContextProvider>
                </NavigationParamsContextProvider>
              </AnalyticsProvider>
            </ThemeProvider>
          </CacheProvider>
        </AuthContextProvider>
      </SWRConfig>
    </QueryClientProvider>
  );
}

// Docs here: https://launchdarkly.github.io/react-client-sdk/
//
// Default behavior includes converting the snakeCase keys generated by LD into camelCase vars for ease of importing
// e.g. "test-flag-1" will be imported from `useFlags()` hook as "testFlag1"
export default withLDProvider({
  clientSideID: config.launchDarklyClientId!,
})(FormBio as React.ComponentType);
