import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FourSquares(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M6.6666 0H0.6666C0.3002 0 0 0.2996 0 0.6666V6.6666C0 7.0326 0.3002 7.3332 0.6666 7.3332H6.6666C7.0332 7.3332 7.3334 7.0324 7.3334 6.6666V0.6666C7.3334 0.2996 7.0332 0 6.6666 0ZM6 6H1.3334V1.3334H6V6Z'
        fill='currentColor'
      />
      <path
        d='M15.3334 0H9.3334C8.9668 0 8.6666 0.2996 8.6666 0.6666V6.6666C8.6666 7.0326 8.9668 7.3332 9.3334 7.3332H15.3334C15.6998 7.3332 16 7.0324 16 6.6666V0.6666C16 0.2996 15.6998 0 15.3334 0ZM14.6666 6H10V1.3334H14.6666V6Z'
        fill='currentColor'
      />
      <path
        d='M6.6666 8.66797H0.6666C0.3002 8.66797 0 8.96737 0 9.33457V15.3346C0 15.7004 0.3002 16.0012 0.6666 16.0012H6.6666C7.0332 16.0012 7.3334 15.7004 7.3334 15.3346V9.33457C7.3334 8.96737 7.0332 8.66797 6.6666 8.66797ZM6 14.668H1.3334V10.0012H6V14.668Z'
        fill='currentColor'
      />
      <path
        d='M15.3334 8.66797H9.33343C8.96683 8.66797 8.66663 8.96737 8.66663 9.33457V15.3346C8.66663 15.7004 8.96683 16.0012 9.33343 16.0012H15.3334C15.6998 16.0012 16 15.7004 16 15.3346V9.33457C16 8.96737 15.6998 8.66797 15.3334 8.66797ZM14.6666 14.668H10V10.0012H14.6666V14.668Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
