/**
 * The workflowRun has metadata about the run
 * like config params, id and when it was created
 * It also has an array of executions to track the actual
 * nextflow execution
 */
export interface WorkflowRun {
  createdAt: string;
  id: string;
  name: string;
  outputDirectory: string;
  nextflowVersion: string;
  params: WorkflowRunParams;
  repository: string;
  repositoryVersion?: string;
  executions: WorkflowRunExecution[];
  canVisualizeWithRShiny: boolean;
  projectName?: string;
  workflowDisplayName: string;
}

export type WorkflowRunParams = Record<string, string | boolean | number>;

export enum WorkflowRunStatus {
  Completed = 'COMPLETED',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
  CompletedWithWarning = 'COMPLETED WITH WARNING',
}

/**
 * A workflowRun includes an array of executions
 * that represent each "actual" execution of the
 * same workflow configuration.
 * Each time a run is paused, canceled, retried,
 * a new execution is created.
 * It mostly tracks who ran it and when.
 * executions[0] is always the most recent.
 */
export interface WorkflowRunExecution {
  id: string;
  workflowRunId: string;
  userName: string;
  userEmail: string;
  status: WorkflowRunStatus;
  createdAt: string;
  updatedAt: string;
  workflowStatus: string;
  elapsedTimeInMillis?: number;
  completedAt?: string;
}
