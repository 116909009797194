import { CustomComponent } from 'styles/type-extension';
import { primary } from '../../palette/primary';

export const MuiDivider: CustomComponent<'MuiDivider'> = {
  styleOverrides: {
    root: {
      '::before, ::after': {
        borderColor: primary[700],
      },
      color: primary[50],
    },
  },
};
