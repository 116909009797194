import { CustomComponent } from 'styles/type-extension';
import { error } from '../palette/error';
import { info } from '../palette/info';
import { primary } from '../palette/primary';
import { success } from '../palette/success';
import { warning } from '../palette/warning';

export const MuiSnackbar: CustomComponent<'MuiSnackbar'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiPaper-root': {
        borderWidth: `4px 0 0 0`,
        backgroundColor: theme.palette.background.default,
        color: primary[900],
        fontWeight: 500,
      },
      '& .MuiAlert-outlinedError': {
        borderColor: error[500],
        '& .MuiAlert-icon': {
          color: error[500],
        },
      },
      '& .MuiAlert-outlinedInfo': {
        borderColor: info[500],
        '& .MuiAlert-icon': {
          color: info[500],
        },
      },
      '& .MuiAlert-outlinedWarning': {
        borderColor: warning[500],
        '& .MuiAlert-icon': {
          color: warning[500],
        },
      },
      '& .MuiAlert-outlinedSuccess': {
        borderColor: success[500],
        '& .MuiAlert-icon': {
          color: success[500],
        },
      },
      '& .MuiAlert-action': {
        color: primary[500],
      },
    }),
  },
};
