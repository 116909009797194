import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Divide(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
      <path
        d='M28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16ZM16 10C16.3956 10 16.7822 9.8827 17.1111 9.66294C17.44 9.44318 17.6964 9.13082 17.8478 8.76537C17.9991 8.39992 18.0387 7.99778 17.9616 7.60982C17.8844 7.22186 17.6939 6.86549 17.4142 6.58579C17.1345 6.30608 16.7781 6.1156 16.3902 6.03843C16.0022 5.96126 15.6001 6.00087 15.2346 6.15224C14.8692 6.30362 14.5568 6.55996 14.3371 6.88886C14.1173 7.21776 14 7.60444 14 8C14 8.53043 14.2107 9.03914 14.5858 9.41421C14.9609 9.78929 15.4696 10 16 10ZM16 22C15.6044 22 15.2178 22.1173 14.8889 22.3371C14.56 22.5568 14.3036 22.8692 14.1522 23.2346C14.0009 23.6001 13.9613 24.0022 14.0384 24.3902C14.1156 24.7781 14.3061 25.1345 14.5858 25.4142C14.8655 25.6939 15.2219 25.8844 15.6098 25.9616C15.9978 26.0387 16.3999 25.9991 16.7654 25.8478C17.1308 25.6964 17.4432 25.44 17.6629 25.1111C17.8827 24.7822 18 24.3956 18 24C18 23.4696 17.7893 22.9609 17.4142 22.5858C17.0391 22.2107 16.5304 22 16 22Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
