import { SvgIcon, SvgIconProps } from '@mui/material';

export default function GenomicsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M10.6455 9.9568C9.98908 10.6173 9.04084 11.1101 8.10824 11.5924C6.49572 12.4286 4.97438 13.215 4.97438 14.7615C4.97438 14.8925 4.86758 15 4.73733 15C4.60707 15 4.50027 14.8925 4.50027 14.7615C4.50027 12.9266 6.2222 12.0328 7.89202 11.1704C9.50454 10.3343 11.0259 9.54789 11.0259 8.00138C11.0259 6.45487 9.50454 5.66851 7.89202 4.83235C6.2248 3.96735 4.50027 3.07614 4.50027 1.2413C4.50027 1.11024 4.60707 1.00277 4.73733 1.00277C4.86758 1.00277 4.97438 1.11024 4.97438 1.2413C4.97438 2.78781 6.49573 3.57417 8.10824 4.41034C9.77547 5.27533 11.5 6.16654 11.5 8.00139C11.5 8.80872 11.1639 9.43518 10.6455 9.9568Z'
        fill='currentColor'
      />
      <path
        d='M11.4294 14.9291C11.3877 14.971 11.3278 14.9998 11.2627 14.9972C11.1324 14.9972 11.0256 14.8898 11.0256 14.7587C11.0256 13.2122 9.50167 12.4232 7.89176 11.5897C6.22453 10.7247 4.5 9.83346 4.5 7.99862C4.5 6.16377 6.22453 5.27256 7.89176 4.40757C9.50427 3.5714 11.0256 2.78504 11.0256 1.23853C11.0256 1.10747 11.1324 1 11.2627 1C11.3929 1 11.4997 1.10747 11.4997 1.23853C11.4997 3.07337 9.7752 3.96458 8.10797 4.82958C6.49546 5.66574 4.97151 6.45473 4.97412 7.99861C4.97412 9.54512 6.49546 10.3315 8.10797 11.1676C9.7752 12.0326 11.4997 12.9239 11.4997 14.7587C11.4997 14.8268 11.4737 14.8845 11.4294 14.9291Z'
        fill='currentColor'
      />
      <path
        d='M10.6455 9.9568C9.98908 10.6173 9.04084 11.1101 8.10824 11.5924C6.49572 12.4286 4.97438 13.215 4.97438 14.7615C4.97438 14.8925 4.86758 15 4.73733 15C4.60707 15 4.50027 14.8925 4.50027 14.7615C4.50027 12.9266 6.2222 12.0328 7.89202 11.1704C9.50454 10.3343 11.0259 9.54789 11.0259 8.00138C11.0259 6.45487 9.50454 5.66851 7.89202 4.83235C6.2248 3.96735 4.50027 3.07614 4.50027 1.2413C4.50027 1.11024 4.60707 1.00277 4.73733 1.00277C4.86758 1.00277 4.97438 1.11024 4.97438 1.2413C4.97438 2.78781 6.49573 3.57417 8.10824 4.41034C9.77547 5.27533 11.5 6.16654 11.5 8.00139C11.5 8.80872 11.1639 9.43518 10.6455 9.9568Z'
        stroke='currentColor'
      />
      <path
        d='M11.4294 14.9291C11.3877 14.971 11.3278 14.9998 11.2627 14.9972C11.1324 14.9972 11.0256 14.8898 11.0256 14.7587C11.0256 13.2122 9.50167 12.4232 7.89176 11.5897C6.22453 10.7247 4.5 9.83346 4.5 7.99862C4.5 6.16377 6.22453 5.27256 7.89176 4.40757C9.50427 3.5714 11.0256 2.78504 11.0256 1.23853C11.0256 1.10747 11.1324 1 11.2627 1C11.3929 1 11.4997 1.10747 11.4997 1.23853C11.4997 3.07337 9.7752 3.96458 8.10797 4.82958C6.49546 5.66574 4.97151 6.45473 4.97412 7.99861C4.97412 9.54512 6.49546 10.3315 8.10797 11.1676C9.7752 12.0326 11.4997 12.9239 11.4997 14.7587C11.4997 14.8268 11.4737 14.8845 11.4294 14.9291Z'
        stroke='currentColor'
      />
    </SvgIcon>
  );
}
