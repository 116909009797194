import { SvgIcon, SvgIconProps } from '@mui/material';

export default function XCircle(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M10.5303 6.53033C10.8232 6.23744 10.8232 5.76256 10.5303 5.46967C10.2374 5.17678 9.76256 5.17678 9.46967 5.46967L10.5303 6.53033ZM5.46967 9.46967C5.17678 9.76256 5.17678 10.2374 5.46967 10.5303C5.76256 10.8232 6.23744 10.8232 6.53033 10.5303L5.46967 9.46967ZM9.46967 10.5303C9.76256 10.8232 10.2374 10.8232 10.5303 10.5303C10.8232 10.2374 10.8232 9.76256 10.5303 9.46967L9.46967 10.5303ZM6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033L6.53033 5.46967ZM13.25 8C13.25 10.8995 10.8995 13.25 8 13.25V14.75C11.7279 14.75 14.75 11.7279 14.75 8H13.25ZM8 13.25C5.10051 13.25 2.75 10.8995 2.75 8H1.25C1.25 11.7279 4.27208 14.75 8 14.75V13.25ZM2.75 8C2.75 5.10051 5.10051 2.75 8 2.75V1.25C4.27208 1.25 1.25 4.27208 1.25 8H2.75ZM8 2.75C10.8995 2.75 13.25 5.10051 13.25 8H14.75C14.75 4.27208 11.7279 1.25 8 1.25V2.75ZM9.46967 5.46967L5.46967 9.46967L6.53033 10.5303L10.5303 6.53033L9.46967 5.46967ZM10.5303 9.46967L6.53033 5.46967L5.46967 6.53033L9.46967 10.5303L10.5303 9.46967Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
