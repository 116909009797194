import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CaretDownFilled(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M13 6L8 11L3 6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
