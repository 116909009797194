import { DEFAULT_BORDER_RADIUS } from '@/constants';
import { CustomComponent } from 'styles/type-extension';

export const MuiMenuItem: CustomComponent<'MuiMenuItem'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: DEFAULT_BORDER_RADIUS,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      minWidth: '240px',
      '&.Mui-selected': {
        backgroundColor: theme.palette.info[100],
        color: theme.palette.primary[900],
      },
      ':hover': {
        backgroundColor: theme.palette.primary[100],
      },
      '&.Mui-focusVisible': {
        background: 'none',
        boxShadow: `0px 0px 0px 4px ${theme.palette.info[300]}`,
      },
    }),
  },
};
