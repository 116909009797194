export interface VaultItem {
  createdAt: string;
  updatedAt: string;
  metadata: Record<string, string> | null;
  size: number;
  contentType: string;
  bucket: string;
  name: string;
}

export interface FetchVaultParams {
  prefix?: string;
  shouldRefresh: boolean;
}

// flags to navigate naming conflicts during storage operations, i.e. create, upload, copy, move
// starting point for context can be found here: https://formbio.atlassian.net/browse/FORM-1473
export enum ConflictStrategy {
  DEFAULT = 'default',
  MERGE = 'merge',
  REPLACE = 'replace',
}

export interface CopyVaultItemParams {
  path: string;
  destination: string;
  destinationOrg?: string;
  destinationProject?: string;
}

export interface FetchSignedURLParams {
  oid: string;
  pid: string;
  path: string;
  baseURL: string;
}
