import { CustomComponent } from 'styles/type-extension';
import { primary } from '../../palette/primary';
import { secondary } from '../../palette/secondary';

export const MuiTextField: CustomComponent<'MuiTextField'> = {
  styleOverrides: {
    root: {
      '& label.Mui-focused': {
        color: secondary[600],
      },
      '& input': {
        backgroundColor: primary[800],
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: primary[400],
        },
        '&:hover fieldset': {
          borderColor: primary[500],
        },
        '&.Mui-focused fieldset': {
          borderColor: secondary[600],
          borderWidth: '1px',
          boxShadow: `0px 0px 0px 4px ${secondary[300]}`,
        },
      },
    },
  },
};
