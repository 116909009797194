import { SvgIconProps, SvgIcon } from '@mui/material';

export default function GeneFinding(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.38483 0.985877C7.57208 0.878601 7.78415 0.822144 8 0.822144C8.21585 0.822144 8.42792 0.878601 8.61517 0.985877L13.8641 3.95403C14.0562 4.06311 14.2161 4.22091 14.3278 4.41151C14.4394 4.60211 14.4988 4.81879 14.5 5.03968L14.5 5.04371L14.5 10.9562H13.75L14.5 10.9602C14.4988 11.1811 14.4394 11.3978 14.3278 11.5884C14.2161 11.779 14.0562 11.9368 13.8641 12.0459L13.8629 12.0466L8.6151 15.0141C8.42786 15.1213 8.21582 15.1778 8 15.1778C7.78418 15.1778 7.57214 15.1213 7.3849 15.0141L2.13708 12.0466L2.13589 12.0459C1.94381 11.9368 1.78389 11.779 1.67225 11.5884C1.56061 11.3978 1.5012 11.1811 1.50001 10.9602L1.5 10.9562V5.03968C1.50119 4.81879 1.56061 4.60212 1.67225 4.41151C1.78389 4.22091 1.94381 4.06311 2.13589 3.95403L7.38483 0.985877ZM13 9.76452V5.18861L9.29338 3.0926L13 9.76452ZM8 9.96826L9.91217 7.1H6.08783L8 9.96826ZM7.69068 12.1H5.27896L8 13.6387L10.721 12.1H7.70812C7.70231 12.1001 7.6965 12.1001 7.69068 12.1ZM8.82106 10.9H12.2581L10.6958 8.0879L8.82106 10.9ZM5.30421 8.0879L7.17894 10.9H3.74193L5.30421 8.0879ZM9.48029 5.9L8 3.23548L6.51971 5.9H9.48029ZM3 9.76452L6.70662 3.0926L3 5.18861V9.76452Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
