import type { WorkflowRun } from '../../types/workflow-run';
import { api } from '../../utils/api';

export function fetchAll(oid: string, pid: string) {
  return api
    .get<WorkflowRun[]>(`/orgs/${oid}/${pid}/workflow-runs`)
    .then(({ data }) => data);
}

export function fetchOne(id: string) {
  return api.get<WorkflowRun>(`/workflow-run/${id}`).then(({ data }) => data);
}

export function remove(id: string, params?: { removeOutput?: boolean }) {
  return api.delete(`/workflow-run/${id}`, { params });
}
