import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  CreateProjectData, MemberData, UpdateProjectData
} from '../../services/projects';
import {
  addMember, addMembers, create, fetchAll,
  fetchOne,
  remove,
  removeMember, update, updateMember
} from '../../services/projects';
import useInvalidation from '../useInvalidation';

const KEY = 'projects';

export function useProjects() {
  return useQuery([KEY], () => fetchAll(), { retry: 1 });
}

export function useProjectsForOrganization(oid?: string) {
  return useQuery(
    [KEY, oid],
    () => {
      if (oid) {
        return fetchAll(oid);
      }
    },
    {
      enabled: !!oid,
    },
  );
}

export function useAllProjectsForOrganization(oid?: string) {
  return useQuery([KEY], () => fetchAll(), {
    select: (projects) =>
      projects.filter((project) => project.organization.id === oid),
    enabled: !!oid,
  });
}

export function useProject(oid?: string, pid?: string) {
  return useQuery(
    [KEY, { oid, pid }],
    () => {
      if (oid && pid) {
        return fetchOne(oid, pid);
      }
    },
    {
      enabled: !!oid && !!pid,
    },
  );
}

export function useRemoveProjectMember(oid: string, pid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((email: string) => removeMember(oid, pid, email), {
    onSuccess: () => {
      return invalidate({ oid, pid });
    },
  });
}

export function useAddProject() {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: CreateProjectData) => create(data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useEditProject() {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: UpdateProjectData) => update(data), {
    onSuccess: () => {
      return invalidate()
    },
  });
}

export function useRemoveProject(oid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((pid: string) => remove(oid, pid), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useAddProjectMember(oid: string, pid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData) => addMember(oid, pid, data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}

export function useUpdateProjectMember(oid: string, pid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData) => updateMember(oid, pid, data), {
    onSuccess: () => {
      return invalidate({ oid, pid });
    },
  });
}

export function useAddProjectMembers(oid: string, pid: string) {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: MemberData[]) => addMembers(oid, pid, data), {
    onSuccess: () => {
      return invalidate({ oid, pid });
    },
  });
}
