import { SvgIcon, SvgIconProps } from '@mui/material';

export default function TwoToneCheck({
  foregroundColor = 'currentColor',
  backgroundColor = 'currentColor',
  ...props
}: {
  foregroundColor?: string;
  backgroundColor?: string;
} & SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <rect width='16' height='16' rx='4' fill={backgroundColor} />
      <path
        d='M11.4375 5.8125L7.0625 10.1875L4.875 8'
        stroke={foregroundColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
