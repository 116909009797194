import { SvgIcon, SvgIconProps } from '@mui/material';

export default function EqualizerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='13' viewBox='0 0 14 13' {...props}>
      <path
        d='M6.75 5.25V12M6.75 5.25C7.44036 5.25 8 4.69036 8 4C8 3.30964 7.44036 2.75 6.75 2.75M6.75 5.25C6.05964 5.25 5.5 4.69036 5.5 4C5.5 3.30964 6.05964 2.75 6.75 2.75M6.75 1V2.75M11.25 10.25V12M11.25 10.25C11.9404 10.25 12.5 9.69036 12.5 9C12.5 8.30964 11.9404 7.75 11.25 7.75M11.25 10.25C10.5596 10.25 10 9.69036 10 9C10 8.30964 10.5596 7.75 11.25 7.75M11.25 1V7.75M2.25 8.25V12M2.25 8.25C2.94036 8.25 3.5 7.69036 3.5 7C3.5 6.30964 2.94036 5.75 2.25 5.75M2.25 8.25C1.55964 8.25 1 7.69036 1 7C1 6.30964 1.55964 5.75 2.25 5.75M2.25 1V5.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
