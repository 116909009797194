import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CandidateValidation(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
      <path
        d='M11.25 9C10.2835 9 9.5 9.7835 9.5 10.75C9.5 11.7165 10.2835 12.5 11.25 12.5C12.2165 12.5 13 11.7165 13 10.75C13 9.7835 12.2165 9 11.25 9ZM11.25 9V6.49375C11.2489 5.69673 10.932 4.93262 10.3687 4.36875L8 2M8 2V4.5M8 2H10.5M2.75 5C3.7165 5 4.5 4.2165 4.5 3.25C4.5 2.2835 3.7165 1.5 2.75 1.5C1.7835 1.5 1 2.2835 1 3.25C1 4.2165 1.7835 5 2.75 5ZM2.75 5V7.50625C2.75108 8.30327 3.06796 9.06738 3.63125 9.63125L6 12M6 12V9.5M6 12H3.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
