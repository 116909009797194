import { DEFAULT_BORDER_RADIUS } from '@/constants';
import { info } from 'styles/themes/formbio/palette/info';
import { CustomComponent } from 'styles/type-extension';
import { primary } from '../palette/primary';

export const MuiListItemButton: CustomComponent<'MuiListItemButton'> = {
  styleOverrides: {
    root: {
      borderRadius: DEFAULT_BORDER_RADIUS,
      '.MuiListItemText-root': {
        color: primary[900],
      },
      '&.MuiListItemButton-root.Mui-selected': {
        backgroundColor: info[100],
        color: primary[900],
        '.MuiListItemText-root': {
          color: primary[900],
        },
      },
      ':hover': {
        backgroundColor: info[100],
      },
      '&.Mui-focusVisible': {
        background: 'none',
        boxShadow: `0px 0px 0px 4px ${info[300]}`,
      },
    },
  },
};
