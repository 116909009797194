import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        d='M12.5 3.5L3.5 12.5M12.5 12.5L3.5 3.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
