import { useMutation, useQuery } from '@tanstack/react-query';

import {
  fetchOne,
  fetchAll,
  CreateData,
  create,
} from '../../services/tool-runs';
import { ToolRunResponse } from '../../types/tool-run';
import useInvalidation from '../useInvalidation';

const KEY = 'toolrun';

export function useToolRuns(params: Parameters<typeof fetchAll>[0]) {
  return useQuery([KEY, params], () => fetchAll(params));
}

export function useToolRun(
  params: Parameters<typeof fetchOne>[0],
  refetchInterval?: (_data: ToolRunResponse | undefined) => number | false,
) {
  return useQuery([KEY, params], () => fetchOne(params), {
    refetchInterval,
  });
}

export function useAddToolRun() {
  const invalidate = useInvalidation(KEY);

  return useMutation((data: CreateData) => create(data), {
    onSuccess: () => {
      return invalidate();
    },
  });
}
