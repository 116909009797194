import type { Workflow } from '../../types/workflow';
import { api } from '../../utils/api';

export function fetchAll(oid: string, pid: string) {
  return api
    .get<{ workflows: Workflow[] }>(`/orgs/${oid}/${pid}/workflows`)
    .then(({ data }) => data.workflows);
}

export function fetchOne(params: {
  oid: string;
  pid: string;
  repo: string;
  name: string;
  version?: string;
}) {
  const { oid, pid, repo, name, version } = params;
  return api
    .get<{ workflows: Workflow[] }>(
      `/orgs/${oid}/${pid}/workflows/${repo}/${name}`,
      {
        params: {
          version,
        },
      },
    )
    .then(({ data }) => data.workflows);
}
