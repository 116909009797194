import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { start } from '../../services/rshiny';

export function useRShinySession({
  oid,
  pid,
  runid,
  options,
}: {
  oid: string;
  pid: string;
  runid: string;
  options?: UseMutationOptions;
}) {
  return useMutation(() => start(oid, pid, runid), options);
}
