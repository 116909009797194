import { CustomComponent } from 'styles/type-extension';
import { primary } from '../palette/primary';

export const MuiTableHead: CustomComponent<'MuiTableHead'> = {
  styleOverrides: {
    root: {
      '& .MuiTableCell-root .MuiTypography-root': {
        color: primary[900],
      },
    },
  },
};
