import { DEFAULT_BORDER_RADIUS } from '@/constants';
import { Checkbox, LinearProgress } from '@mui/material';
import typography from 'styles/themes/formbio/typography';
import { CustomComponent } from 'styles/type-extension';
import { primary } from '../palette/primary';
import { info } from '../palette/info';

export const MuiDataGrid: CustomComponent<'MuiDataGrid'> = {
  styleOverrides: {
    root: {
      border: 'none',
      visibility: 'visible',
      /**
       * Fixes a bug in Datagrid where overlays are behind the virtual scroller
       *
       * Convoluted but necessary selector to enable interactions
       * with the overlay (such as NoRowsOverlay)
       * The overlay (when present) is the 1st child of .MuiDataGrid-main
       * When not present, the first child is .MuiDataGrid-columnHeaders
       */
      '.MuiDataGrid-main > div:nth-child(1):not(.MuiDataGrid-columnHeaders)': {
        //replaces inline styling position: absolute
        position: 'relative !important',
      },
    },
    cell: {
      outline: 'none',
      cursor: 'pointer',
      ':focus': {
        outline: 'none',
      },
      ':focus-within': {
        outline: 'none',
      },
    },
    columnHeaderTitle: {
      ...typography.button2,
    },
    columnHeader: {
      ':focus': {
        outline: 'none',
      },
      ':focus-within': {
        outline: 'none',
      },
    },
    columnSeparator: {
      visibility: 'hidden',
    },
    selectedRowCount: {
      visibility: 'hidden',
    },
    checkboxInput: {
      borderRadius: DEFAULT_BORDER_RADIUS,
      padding: '5px',
      color: primary[400],
      fontSize: '1rem !important',
      '&.Mui-checked': {
        color: primary[900],
      },
      '&.MuiSvgIcon': {
        fontSize: '1rem',
      },
    },
    row: {
      '&.Mui-hovered': {
        backgroundColor: primary[100],
      },
      '&.Mui-selected': {
        backgroundColor: info[100],
      },
      '&.Mui-selected:hover': {
        backgroundColor: info[100],
      },
    },
    pinnedColumns: {
      boxShadow: 'none',
    },
    pinnedColumnHeaders: {
      boxShadow: 'none',
    },
  },
  defaultProps: {
    components: {
      LoadingOverlay: LinearProgress,
      BaseCheckbox: Checkbox,
    },
    componentsProps: {
      baseCheckbox: { size: 'small' },
    },
  },
};
