import { SvgIcon, SvgIconProps } from '@mui/material';

export default function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M27 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
