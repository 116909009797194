import { SvgIcon, SvgIconProps } from '@mui/material';

export default function WarningCircleOutlined(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeMiterlimit='10'
        fill='none'
      />
      <path
        d='M16.5 10V17'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M16.5 23C17.3284 23 18 22.3284 18 21.5C18 20.6716 17.3284 20 16.5 20C15.6716 20 15 20.6716 15 21.5C15 22.3284 15.6716 23 16.5 23Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
