import { SvgIcon, SvgIconProps } from '@mui/material';

export default function GeneFinding(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='14' viewBox='0 0 14 14' {...props}>
      <path
        d='M4 9H12C12.1326 9 12.2598 8.94732 12.3536 8.85355C12.4473 8.75979 12.5 8.63261 12.5 8.5V2C12.5 1.86739 12.4473 1.74021 12.3536 1.64645C12.2598 1.55268 12.1326 1.5 12 1.5H5C4.86739 1.5 4.74021 1.55268 4.64645 1.64645C4.55268 1.74021 4.5 1.86739 4.5 2V2.5M4 9L5.5 7.5M4 9L5.5 10.5M10 5H2C1.86739 5 1.74021 5.05268 1.64645 5.14645C1.55268 5.24021 1.5 5.36739 1.5 5.5V12C1.5 12.1326 1.55268 12.2598 1.64645 12.3536C1.74021 12.4473 1.86739 12.5 2 12.5H9C9.13261 12.5 9.25979 12.4473 9.35355 12.3536C9.44732 12.2598 9.5 12.1326 9.5 12V11.5M10 5L8.5 6.5M10 5L8.5 3.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
