import type {} from '@mui/lab/themeAugmentation'; // this import allows the components section to recognize "lab" components until it gets baked in in v5
import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation'; // this import allows the components section to recognize MuiDataGrid components
import {
  componentsDark,
  default as componentsFormbio,
} from './themes/formbio/components';
import {
  default as paletteFormbio,
  paletteDark,
} from './themes/formbio/palette';
import {
  default as typographyFormbio,
  typographyDark,
} from './themes/formbio/typography';
import { ExtendedPalette, ExtendedTypography } from './type-extension';

declare module '@mui/material/styles' {
  // this actually adds custom overrides to the Theme interface
  interface Theme {
    palette: ExtendedPalette;
    typography: ExtendedTypography;
  }
}

export const themeFormbio: Theme = responsiveFontSizes(
  createTheme({
    components: componentsFormbio,
    typography: typographyFormbio,
    palette: paletteFormbio,
  }),
);

export const themeFormbioDark: Theme = responsiveFontSizes(
  createTheme({
    // fallback to regular light formbio components
    components: { ...componentsFormbio, ...componentsDark },
    typography: typographyDark,
    palette: paletteDark,
  }),
);

// to keep all the static imports working for now.
// this will be removed in the future.
export default themeFormbio;

//height taken by the top toolbar, the breadcrumb and the navigation tabs
export const toolbarStyle = {
  height: {
    normal: 80,
    dense: 48,
  },
};
