import { Tool, Tools } from '../../types/toolbox';
import { api } from '../../utils/api';

// Commented code is for testing the demos tools
// import * as demos from '../../utils/toolbox-demos';

export async function fetchAll(): Promise<Tools> {
  // return new Promise(res => res(Object.values(demos)));
  return api.get<Tools>(`/tools`).then(({ data }) => data);
}

export function fetchOne({ slug }: { slug: string }) {
  // return new Promise<Tool>(res => res((demos as Record<string, Tool>)[slug]));
  return api.get<Tool>(`/tools/${slug}`).then(({ data }) => data);
}
