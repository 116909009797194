import { SvgIcon, SvgIconProps } from '@mui/material';

export default function UserPlus(props: SvgIconProps) {
  return (
    <SvgIcon width='17' height='16' viewBox='0 0 17 16' {...props}>
      <path
        d='M12.5 7.75C12.0858 7.75 11.75 8.08579 11.75 8.5C11.75 8.91421 12.0858 9.25 12.5 9.25V7.75ZM15.5 9.25C15.9142 9.25 16.25 8.91421 16.25 8.5C16.25 8.08579 15.9142 7.75 15.5 7.75V9.25ZM14.75 7C14.75 6.58579 14.4142 6.25 14 6.25C13.5858 6.25 13.25 6.58579 13.25 7H14.75ZM13.25 10C13.25 10.4142 13.5858 10.75 14 10.75C14.4142 10.75 14.75 10.4142 14.75 10H13.25ZM0.812959 12.0179C0.546721 12.3353 0.588128 12.8083 0.905446 13.0746C1.22276 13.3408 1.69583 13.2994 1.96207 12.9821L0.812959 12.0179ZM11.538 12.9821C11.8042 13.2994 12.2773 13.3408 12.5946 13.0746C12.9119 12.8083 12.9533 12.3353 12.6871 12.0179L11.538 12.9821ZM12.5 9.25H15.5V7.75H12.5V9.25ZM13.25 7V10H14.75V7H13.25ZM9.75001 6.25C9.75001 7.90685 8.40687 9.25 6.75001 9.25V10.75C9.23529 10.75 11.25 8.73528 11.25 6.25H9.75001ZM6.75001 9.25C5.09316 9.25 3.75001 7.90685 3.75001 6.25H2.25001C2.25001 8.73528 4.26473 10.75 6.75001 10.75V9.25ZM3.75001 6.25C3.75001 4.59315 5.09316 3.25 6.75001 3.25V1.75C4.26473 1.75 2.25001 3.76472 2.25001 6.25H3.75001ZM6.75001 3.25C8.40687 3.25 9.75001 4.59315 9.75001 6.25H11.25C11.25 3.76472 9.23529 1.75 6.75001 1.75V3.25ZM1.96207 12.9821C2.5487 12.2829 3.28136 11.7207 4.10853 11.3349L3.47457 9.97547C2.44888 10.4538 1.54039 11.1509 0.812959 12.0179L1.96207 12.9821ZM4.10853 11.3349C4.93538 10.9493 5.83697 10.75 6.75001 10.75V9.25C5.61862 9.25 4.50058 9.49701 3.47457 9.97547L4.10853 11.3349ZM6.75001 10.75C7.66306 10.75 8.56464 10.9493 9.3915 11.3349L10.0255 9.97547C8.99944 9.49701 7.8814 9.25 6.75001 9.25V10.75ZM9.3915 11.3349C10.2187 11.7207 10.9513 12.2829 11.538 12.9821L12.6871 12.0179C11.9596 11.1509 11.0511 10.4538 10.0255 9.97547L9.3915 11.3349Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
