import { getAuth, getIdToken } from 'firebase/auth';
import { app } from 'lib/firebase';

export async function getAuthToken() {
  const auth = getAuth(app);
  if (auth.currentUser) {
    return await getIdToken(auth.currentUser);
  }
  return null;
}
