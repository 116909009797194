// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import config from './config';
const { sentryDSN, sentryENV } = config;

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    //if console: true, Sentry handles the console.log and other print out
    //if console: false, DevTools (Chrome) or the Firefox equivalent handles it.
    //it's better to keep it false for local deployments so we can set breakpoints and such
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: true,
      }),
    ],
    environment: sentryENV,
    ignoreErrors: [/data will be lost if you leave this page/i], // intentional error thrown from <RouteChangeCatchDialog />
  });
}
