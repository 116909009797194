import { Components } from '@mui/material/styles';
import { Theme } from '@mui/system';
import * as MuiButtonDark from './dark/MuiButton';
import * as MuiDividerDark from './dark/MuiDivider';
import * as MuiLinkDark from './dark/MuiLink';
import * as MuiTextFieldDark from './dark/MuiTextField';
import * as MuiButton from './MuiButton';
import * as MuiChip from './MuiChip';
import * as MuiDataGrid from './MuiDataGrid';
import * as MuiDialog from './MuiDialog';
import * as MuiIconButton from './MuiIconButton';
import * as MuiLink from './MuiLink';
import * as MuiListItemButton from './MuiListItemButton';
import * as MuiSnackbar from './MuiSnackbar';
import * as MuiTab from './MuiTab';
import * as MuiTable from './MuiTable';
import * as MuiTextField from './MuiTextField';
import * as MuiTooltip from './MuiTooltip';
import * as MuiMenuItem from './MuiMenuItem';

const components: Components<Theme> = {
  ...MuiButton,
  ...MuiDataGrid,
  ...MuiTextField,
  ...MuiIconButton,
  ...MuiTooltip,
  ...MuiListItemButton,
  ...MuiChip,
  ...MuiTab,
  ...MuiLink,
  ...MuiSnackbar,
  ...MuiTable,
  ...MuiDialog,
  ...MuiMenuItem,
};

export default components;

export const componentsDark: Components<Theme> = {
  ...MuiButtonDark,
  ...MuiDataGrid,
  ...MuiLinkDark,
  ...MuiDividerDark,
  ...MuiTextFieldDark,
};
