import { CustomComponent } from 'styles/type-extension';
import { info } from '../palette/info';

export const MuiLink: CustomComponent<'MuiLink'> = {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      color: info[500],
    },
  },
};
