import theme from 'styles/theme';

export default function ArrowUDownRight({
  color = theme.palette.text.primary,
}: {
  color?: string;
}) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 7.5L14 10.5L11 13.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 3.5H5.5C4.57174 3.5 3.6815 3.86875 3.02513 4.52513C2.36875 5.1815 2 6.07174 2 7C2 7.92826 2.36875 8.8185 3.02513 9.47487C3.6815 10.1313 4.57174 10.5 5.5 10.5H14'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
