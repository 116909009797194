import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export default function useLaunchDarkly() {
  const ldClient = useLDClient();
  const flags = useFlags();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  useEffect(() => {
    ldClient?.on('failed', () => {
      setIsFailed(true);
    });

    ldClient?.waitUntilReady().then(() => {
      setIsReady(true);
    });
  }, [ldClient]);

  return { ready: isReady, failed: isFailed, flags };
}
