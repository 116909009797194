import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CubeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        d='M14 11.0812V4.91871C13.9995 4.82969 13.9756 4.74238 13.9306 4.66556C13.8856 4.58875 13.8212 4.52516 13.7437 4.48121L8.24375 1.38746C8.16964 1.34467 8.08557 1.32214 8 1.32214C7.91443 1.32214 7.83036 1.34467 7.75625 1.38746L2.25625 4.48121C2.17884 4.52516 2.1144 4.58875 2.06941 4.66556C2.02442 4.74238 2.00048 4.82969 2 4.91871V11.0812C2.00048 11.1702 2.02442 11.2575 2.06941 11.3343C2.1144 11.4112 2.17884 11.4747 2.25625 11.5187L7.75625 14.6125C7.83036 14.6552 7.91443 14.6778 8 14.6778C8.08557 14.6778 8.16964 14.6552 8.24375 14.6125L13.7437 11.5187C13.8212 11.4747 13.8856 11.4112 13.9306 11.3343C13.9756 11.2575 13.9995 11.1702 14 11.0812V11.0812Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M13.9312 4.66248L8.05623 7.99998L2.06873 4.66248'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.05625 8L8 14.675'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
