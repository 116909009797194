import {
  FetchVaultParams,
  VaultItem,
  CopyVaultItemParams,
  FetchSignedURLParams,
} from '../../types';
import { api } from '../../utils/api';

export function removeItem(oid: string, pid: string, item: string) {
  return api.delete(`/orgs/${oid}/${pid}/vault`, {
    data: {
      objects: [item],
    },
  });
}

export function fetchOne(
  oid: string,
  pid: string,
  params: Omit<FetchVaultParams, 'shouldRefresh'>,
) {
  return api
    .get<VaultItem[]>(`/orgs/${oid}/${pid}/vault`, { params })
    .then(({ data }) => data);
}

export function renameItem(
  oid: string,
  pid: string,
  oldPath: string,
  newPath: string,
) {
  return api
    .put<VaultItem[]>(`/orgs/${oid}/${pid}/vault/rename`, {
      object: oldPath,
      newName: newPath,
    })
    .then(({ data }) => data);
}

export function copyItem(
  oid: string,
  pid: string,
  params: CopyVaultItemParams,
) {
  const {
    path,
    destination,
    destinationOrg = oid,
    destinationProject = pid,
  } = params;

  return api
    .post<VaultItem[]>(`/orgs/${oid}/${pid}/vault/copy`, {
      destinationOrg,
      destinationProject,
      objects: [path],
      destination,
    })
    .then(({ data }) => data);
}

export function fetchSignedURL(params: FetchSignedURLParams) {
  const { oid, pid, path, baseURL } = params;
  return api
    .get<{ signedURL: string }>(`/orgs/${oid}/${pid}/vault/content/${path}`)
    .then(({ data }) => `${baseURL}${data.signedURL}&serve=proxy`);
}
