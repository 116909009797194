import axios, { AxiosRequestConfig } from 'axios';

export const admin_api = axios.create({});

type InitParams = {
  getToken: () => Promise<string | undefined>;
  baseURL: string;
  onError?: (error: unknown) => void;
};

export function initAdmin({ baseURL, getToken, onError }: InitParams) {
  admin_api.defaults.baseURL = baseURL

  admin_api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = await getToken();

    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  if (onError) {
    admin_api.interceptors.response.use(undefined, (error: unknown) => {
      onError(error);
      throw error;
    });
  }
}