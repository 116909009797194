import { DEFAULT_BORDER_RADIUS } from '@/constants';
import { CustomComponent } from 'styles/type-extension';

export const MuiChip: CustomComponent<'MuiChip'> = {
  styleOverrides: {
    root: {
      borderRadius: DEFAULT_BORDER_RADIUS,
      textTransform: 'capitalize',
    },
  },
};
