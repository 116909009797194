// The flag id referenced in Launch Darkly
export const featureFlagIds = {
  enableIGVWeb: 'enableIgvWeb',
  dryRunWorkflow: 'dryRunWorkflow',
  mockWorkflowRun: 'mockWorkflowRun',
  showManagementPages: 'showManagementPages',
  enableDynamicChunksize: 'dynamicUploadChunksize',
  smallChunksize: 'chunkSizeSmall',
  showProfilePage: 'showProfilePage',
  formbioTheme: 'formbioTheme',
  enableIGVAll: 'enableIgvAll',
  enableRShinyButton: 'enableRShinyButton',
  disableAccountCreation: 'disableAccountCreation',
  enableOrgCreation: 'enableOrgCreation',
  disableCreateAccountPage: 'disableCreateAccountPage',
  enableManageTokens: 'enableManageTokens',
  enableDefaultNextflowVersion21_04_3: 'enableDefaultNextflowVersion21043',
  enableEasterEgg: 'enableEasterEgg',
  enableCopyGcsUrl: 'enableCopyGcsUrl',
  enableToolbox: 'enableToolbox',
  enableQuickNotes: 'enableQuickNotes',
  enableToolboxResultsInput: 'enableToolboxResultsInput',
};

const config = {
  stage: process.env.NEXT_PUBLIC_STAGE,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  visualizationsUrl: process.env.NEXT_PUBLIC_VISUALIZATIONS_URL,
  igvUrl: process.env.NEXT_PUBLIC_VISUALIZATIONS_IGV_URL,
  firebaseApiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  firebaseMeasurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  sentryENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  isLocal: process.env.NEXT_PUBLIC_STAGE?.includes('local'),
  hotjarID: process.env.NEXT_PUBLIC_HOTJAR_ID || '',
  hotjarVersion: process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION || '6',
  banthaApiVersion: process.env.NEXT_PUBLIC_BANTHA_API_VERSION,
  dataGridProLicense: process.env.NEXT_PUBLIC_MUI_X_DATA_GRID_LICENSE,
  launchDarklyClientId: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT,
  segmentAnalyticsApiKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
  handsontableLicense: process.env.NEXT_PUBLIC_HANDSONTABLE_LICENSE,
  useAPIMocking: process.env.NEXT_PUBLIC_USE_API_MOCKING,
  rshinyUrl: process.env.NEXT_PUBLIC_RSHINY_URL,
};

export default config;
