import { admin_api } from '../../../utils/admin-api';
import type { Project } from '../../../types/project';

export interface CustomerJourneyData {
    oid: string;
    projectName: string;
}

export async function fetchAllAdmin(oid?: string) {
    const { data } = await admin_api
        .get<Project[]>(oid ? `/organizations/${oid}/projects` : '/projects');
    return data;
}

export const createCustomerJourney = async (oid: string, projectName: string) => {
    const { data } = await admin_api.post(`/customer-journey/${oid}`, {
        projectName
    })
    return data
}