import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FolderOutlined(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M8.13158 4.48816H14.6689C14.8265 4.48816 14.9776 4.55077 15.0891 4.66222C15.2005 4.77368 15.2632 4.92484 15.2632 5.08246V13.4026C15.2632 13.5602 15.2005 13.7114 15.0891 13.8229C14.9776 13.9343 14.8265 13.9969 14.6689 13.9969H1.5943C1.43668 13.9969 1.28552 13.9343 1.17407 13.8229C1.06261 13.7114 1 13.5602 1 13.4026V6.27105M5.55381 6.27282H1V3.30133C1 3.14371 1.06261 2.99255 1.17407 2.8811C1.28552 2.76964 1.43668 2.70703 1.5943 2.70703H5.55381C5.68231 2.70756 5.80727 2.74921 5.91039 2.82589L8.13158 4.48993L5.91039 6.15396C5.80727 6.23064 5.68231 6.27229 5.55381 6.27282Z'
        stroke='currentColor'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
