import { useQuery } from '@tanstack/react-query';

import { fetchOne } from '../../services/workflow-markdowns';

const KEY = 'workflow-markdowns';

export function useWorkflowMarkdown({
  oid,
  pid,
  wid,
  repo,
  version,
}: {
  oid: string;
  pid: string;
  wid: string;
  repo: string;
  version: string | undefined;
}) {
  return useQuery(
    [KEY, oid, pid, wid, repo, version],
    () => fetchOne({ oid, pid, wid, repo, version }),
    {
      enabled: !!repo,
    },
  );
}
