import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Chart(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M13.3334 0C12.0918 0 11.0586 0.8522 10.7612 2H1.3334C0.6002 2 0 2.5998 0 3.3334V14.6666C0 15.3998 0.6002 16 1.3334 16H12.6666C13.3998 16 14 15.3998 14 14.6666V5.2388C15.1484 4.9416 16 3.9076 16 2.6666C16 1.1944 14.8066 0 13.3334 0ZM12.6666 14.6666H1.3334V10H4L5.3334 13.3334H6L8.3334 7.4232L9.0976 9.3334H12.6666V14.6666ZM12.6666 8H10L8.6666 4.6666H8L5.6666 10.5768L4.9024 8.6666H1.3334V3.3334H10.7612C11.0026 4.267 11.7324 4.997 12.6666 5.2388V8ZM13.3334 4C12.5962 4 12 3.4038 12 2.6666C12 1.9296 12.5962 1.3334 13.3334 1.3334C14.0704 1.3334 14.6666 1.9296 14.6666 2.6666C14.6666 3.4038 14.0704 4 13.3334 4Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
