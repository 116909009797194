import { Snackbar, Alert, AlertColor } from '@mui/material';
import { PropsWithChildren } from 'react';
import { CheckCircle, InfoIcon, WarningCircle, WarningIcon } from '../icons';

export type AlertSnackbarProps = PropsWithChildren<{
  autoHideDuration?: number;
  variant?: AlertColor;
  onClose?: () => void;
}>;

export default function AlertSnackbar({
  autoHideDuration,
  variant = 'info',
  onClose,
  children,
}: AlertSnackbarProps) {
  return (
    <Snackbar open autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert
        severity={variant}
        onClose={onClose}
        elevation={6}
        variant='outlined'
        iconMapping={{
          success: <CheckCircle />,
          info: <InfoIcon />,
          warning: <WarningIcon />,
          error: <WarningCircle />,
        }}
        sx={{ minWidth: '400px' }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
