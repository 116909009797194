import { WorkflowSchemaV2 } from '@formbio/workflow-schema/lib/cjs/types';

enum WorkflowSchemaStatus {
  Ready = 'READY',
}

enum WorkflowSchemaVisibility {
  Organization = 'ORGANIZATION',
}

export interface Workflow {
  Org: string;
  Project: string;
  RepoName: string;
  WorkflowStatus: WorkflowSchemaStatus;
  WorkflowVersion: string;
  WorkflowVisibility: WorkflowSchemaVisibility;
  WorkflowName: string;
  workflowSchema: WorkflowSchemaV2;
  UpdatedAt: string;
}
