import { rest } from 'msw';

const mockWorkflowsV2 = {
  workflows: [
    {
      workflowSchema: {
        schema: 'v2',
        id: 'expressiondetection',
        title: 'formbio/nf-rnaseq',
        workflowVersion: 'main',
        displayName: 'RNASeq: Expression Analysis',
        description:
          'This workflow can be used to determine gene abundances, splice variants and differential expression analysis',
        type: 'object',
        categories: ['RNA', 'NGS', 'Transcriptomics'],
        properties: [
          {
            title: 'Data Files for Analysis',
            type: 'object',
            description: '',
            hidden: false,
            help_text:
              'Select the directory that contains all of the fastQ files for your analysis. Accepted file formats .fq.gz or fastq.gz.  The workflow will match file name to the RunID in your sample metadata file using the text in your filename before _R1, _R2, _1, _2, .R1, .R2, .f[ast]q',
            properties: [
              {
                type: 'string',
                title: 'Directory of Files',
                description: 'Directory Containing Data Files for Analysis',
                help_text:
                  'Select the directory that contains input files for your analysis.',
                format: 'dir-path',
                filetype: '.fastq.gz|.fq.gz|.fasta|.fa|.fq|.fastq',
                hidden: false,
                id: 'input',
              },
            ],
            required: ['input'],
            id: 'indata',
          },
          {
            title: 'Sample Metadata',
            type: 'object',
            description: 'Metadata',
            hidden: false,
            help_text:
              'Select or Create sample metadata file containing the information linking data files to samples and sample descriptions',
            properties: [
              {
                type: 'string',
                title: 'NGS Sample Metadata File',
                format: 'spreadsheet',
                filetype: '.txt',
                hidden: false,
                description:
                  'File Relating RunIDs, SampleIDs and sample attributes such as SampleGroup. See Workflow Documentation for Formatting Instructions.  Sequence data from multiple runs will be merged if they have the same SampleID',
                storageDirectory: 'SampleSheets/',
                columns: {
                  RunID: {
                    generateFromData: true,
                    generateConfig: {
                      delimiterPattern: '_R1|_R2|_1|_2|\\.R',
                      dataName: 'input',
                    },
                    required: true,
                  },
                  SampleID: {
                    pattern: '^\\d|\\W',
                    prependIfNumber: 'S',
                    error:
                      'Sample name must be provided and cannot contain spaces, special characters or start with numbers',
                    required: true,
                  },
                  SampleGroup: {
                    pattern: '\\W',
                    required: true,
                    error:
                      'Group must be provided, cannot contain spaces or special characters',
                  },
                },
                id: 'design',
              },
            ],
            required: ['design'],
            id: 'metadata',
          },
          {
            title: 'Genome',
            type: 'object',
            description: 'Reference Genome',
            hidden: false,
            help_text:
              'Reference organism genome and version of gene annotation',
            properties: [
              {
                type: 'string',
                title: 'Genome',
                description: 'Reference Genome Organism and Assembly Version',
                format: 'enum',
                hidden: false,
                enum: [
                  {
                    id: 'GRCh38',
                    taxid: '9606',
                    name: 'Homo sapiens',
                    commonname: 'Human',
                    snpEffver: 'GRCh38.99',
                    annotations: {
                      latest: {},
                      gencodeV39: {},
                      gencodeV38: {},
                      gencodeV37: {},
                      gencodeV29: {},
                      gencodeV26: {},
                      ensemblV104: {},
                    },
                  },
                  {
                    id: 'GRCm39',
                    name: 'Mus musculus',
                    taxid: '10090',
                    commonname: 'Mouse',
                    annotations: {
                      latest: {},
                      gencodeV28: {},
                      gencodeV26: {},
                    },
                  },
                  {
                    id: 'GRCm38',
                    name: 'Mus musculus',
                    taxid: '10090',
                    snpEffver: 'GRCm38.99',
                    commonname: 'Mouse',
                    annotations: {
                      latest: {},
                      gencodeV23: {},
                    },
                  },
                  {
                    id: 'NC_045512',
                    name: 'COVID19 Severe acute respiratory syndrome coronavirus 2 isolate Wuhan-Hu-1',
                    taxid: '2697049',
                    commonname: 'COVID19',
                    snpEffver: 'NC_045512.2',
                    annotations: {
                      latest: {},
                      ensemblV101: {},
                    },
                  },
                  {
                    id: 'loxAfr3',
                    name: 'Loxodonta africana',
                    taxid: '9785',
                    commonname: 'African Elephant',
                    snpEffver: 'loxAfr3.99',
                    annotations: {
                      latest: {},
                      ensemblV105: {},
                    },
                  },
                ],
                default: 'GRCh38',
                id: 'genomeid',
              },
              {
                type: 'string',
                title: 'Reference genome annotation version',
                hidden: false,
                description: 'Gene Annotation Version',
                format: 'colossalbio/reference-genome[id][annotation-version]',
                default: 'latest',
                id: 'genoannotver',
              },
              {
                type: 'string',
                title: 'NBCI Taxonomy ID',
                hidden: true,
                description:
                  'https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi',
                format: 'colossalbio/reference-genome[id][taxid]',
                id: 'taxid',
              },
            ],
            required: ['genomeid', 'genoannotver', 'taxid'],
            id: 'genome',
          },
          {
            title: 'Tools and Run Parameters',
            type: 'object',
            hidden: false,
            description: 'Advanced Options for Analysis.',
            properties: [
              {
                type: 'boolean',
                title: 'Trim',
                description:
                  'Trim Reads for Adapters, Poor Quality Ends and Remove Reads < 35bp',
                default: true,
                hidden: false,
                id: 'trim',
              },
              {
                type: 'string',
                title: 'Tool for Alignment',
                description: 'RNA Alignment Algorithm',
                format: 'enum',
                default: 'star',
                enum: ['star', 'hisat'],
                help_text: '',
                hidden: false,
                id: 'aligner',
              },
              {
                type: 'string',
                title: 'Mark duplicates',
                description: 'Tool to be used for Mark Duplicates',
                format: 'enum',
                default: 'skip',
                help_text:
                  'Removes reads resulting from PCR or Sequence duplication; set to skip for single-end sequencing',
                enum: ['skip', 'picard'],
                hidden: false,
                id: 'markdups',
              },
              {
                type: 'string',
                title: 'Read orientation',
                description: 'I = inward; O = outward; M = matching',
                format: 'enum',
                default: 'I',
                help_text:
                  'See diagram describing the differences: https://salmon.readthedocs.io/en/latest/library_type.html',
                enum: ['I', 'O', 'M'],
                hidden: false,
                id: 'orientation',
              },
              {
                type: 'string',
                title: 'Read origin',
                description:
                  'Read Origin for Single End Data. F = read 1 (or single-end read) comes from the forward strand. R = read 1 (or single-end read) comes from the reverse strand',
                format: 'enum',
                default: 'F',
                hidden: false,
                enum: ['F', 'R'],
                id: 'readorigin',
              },
              {
                type: 'string',
                title: 'Stranded or Unstranded',
                description: 'S = stranded; U = unstranded',
                format: 'enum',
                default: 'U',
                help_text:
                  'See diagram describing the differences: https://salmon.readthedocs.io/en/latest/library_type.html',
                enum: ['U', 'S'],
                hidden: false,
                id: 'stranded',
              },
            ],
            required: [
              'trim',
              'aligner',
              'markdups',
              'orientation',
              'readorigin',
              'stranded',
            ],
            id: 'advance',
          },
          {
            title: 'Backend-Configuration',
            type: 'object',
            hidden: true,
            description:
              'Configuration of Cloud Projects/Buckets/Container Registry',
            properties: [
              {
                type: 'string',
                format: 'text',
                title: 'Workflow/Docker Version',
                description: 'Tagged Version of Workflows and Containers',
                default: 'latest',
                hidden: true,
                id: 'version',
              },
              {
                type: 'string',
                format: 'dir-path',
                title: 'Reference Genome Repo',
                description:
                  'Bucket for Reference Data Files Used in the Workflows',
                default: 'gs://bioinfo-workflow-references/genomes',
                hidden: true,
                id: 'genomes_bucket',
              },
              {
                type: 'string',
                title: 'Repository Directory',
                description: 'location of scripts on the containers',
                format: 'dir-path',
                default: '/seqprg',
                hidden: true,
                id: 'repoDir',
              },
            ],
            required: ['version', 'genomes_bucket', 'repoDir,'],
            id: 'config',
          },
        ],
        allOf: [
          {
            $ref: '#/properties/inputs',
          },
        ],
      },
    },
  ],
};

const workflowByName = rest.get(
  '/orgs/:orgId/:projectId/workflows',
  (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: mockWorkflowsV2 }));
  },
);

export default workflowByName;
