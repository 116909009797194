export enum OrganizationRole {
  Admin = 'admin',
  Owner = 'owner',
  Member = 'member',
}

export enum ProjectRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
}

export enum Role {
  Editor = 'editor',
  Admin = 'admin',
  Owner = 'owner',
  Viewer = 'viewer',
  Member = 'member',
}

export interface Member {
  user: User;
  role: Role;
  createdAt: string;
  scopes?: string[];
}

interface User {
  created_at: string;
  updated_at: string;
  email: string;
  name: string;
}
