import { SvgIcon, SvgIconProps } from '@mui/material';

export default function TreeStructure(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M5 8H7.5M10 11.5H9C8.80279 11.5008 8.60736 11.4626 8.425 11.3875C8.24264 11.3124 8.07695 11.202 7.9375 11.0625C7.79804 10.9231 7.68759 10.7574 7.6125 10.575C7.53741 10.3926 7.49917 10.1972 7.5 10V6C7.49917 5.80279 7.53741 5.60736 7.6125 5.425C7.68759 5.24264 7.79804 5.07695 7.9375 4.9375C8.07695 4.79804 8.24264 4.68759 8.425 4.6125C8.60736 4.53741 8.80279 4.49917 9 4.5H10M2 6.25H4.5C4.77614 6.25 5 6.47386 5 6.75V9.25C5 9.52614 4.77614 9.75 4.5 9.75H2C1.72386 9.75 1.5 9.52614 1.5 9.25V6.75C1.5 6.47386 1.72386 6.25 2 6.25ZM10.5 2.5H13.5C13.7761 2.5 14 2.72386 14 3V6C14 6.27614 13.7761 6.5 13.5 6.5H10.5C10.2239 6.5 10 6.27614 10 6V3C10 2.72386 10.2239 2.5 10.5 2.5ZM10.5 9.5H13.5C13.7761 9.5 14 9.72386 14 10V13C14 13.2761 13.7761 13.5 13.5 13.5H10.5C10.2239 13.5 10 13.2761 10 13V10C10 9.72386 10.2239 9.5 10.5 9.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
