import { rest } from 'msw';
import workflowByName from './workflow_by_name';
import workflows from './workflows';
import organizations from './data/organizations.json';
import workflowRuns from './data/workflow-runs.json';
import project from './data/project.json';

export const handlers = [
  workflowByName,
  workflows,
  rest.post('https://api.segment.io/v1/*', (_req, res, ctx) => {
    return res(ctx.json({ success: true }));
  }),
  rest.get('*/api/v1/user/projects', (_req, res, ctx) => {
    return res(ctx.json([project]));
  }),
  rest.get('*/api/v1/orgs/:oid/:pid', (req, res, ctx) => {
    if (req.params.oid === 'fake-organization') {
      return res(ctx.json(project));
    } else {
      return res(ctx.status(404));
    }
  }),
  rest.get('*/api/v1/user/orgs', (_req, res, ctx) => {
    return res(ctx.json(organizations));
  }),
  rest.get('*/api/v1/orgs/:oid/:pid/workflows', (_req, res, ctx) => {
    return res(ctx.json({ workflows: [] }));
  }),
  rest.get('*/api/v1/orgs/:oid/:pid/workflow-runs', (_req, res, ctx) => {
    return res(ctx.json(workflowRuns));
  }),
  rest.get('*/api/v1/workflow-run/:runId', (_req, res, ctx) => {
    return res(ctx.json(workflowRuns[1]));
  }),
  rest.get('*/api/v1/orgs/:oid/:pid/vault', (_req, res, ctx) => {
    return res(ctx.json([]));
  }),
  rest.get('https://heapanalytics.com/h', (_req, res, _ctx) => {
    return res();
  }),
  rest.get('https://app.launchdarkly.com/sdk/goals/*', (_req, res, ctx) => {
    return res(ctx.json([]));
  }),
  rest.get('https://app.launchdarkly.com/sdk/evalx/*', (_req, res, ctx) => {
    return res(
      ctx.json({
        'formbio-theme': {
          flagVersion: 34,
          trackEvents: false,
          value: true,
          variation: 0,
          version: 182,
        },
      }),
    );
  }),
  rest.post(
    'https://identitytoolkit.googleapis.com/v1/accounts:action',
    (req, res, ctx) => {
      switch (req.params.action) {
        case ':signInWithPassword':
          return res(
            ctx.json({
              kind: 'identitytoolkit#VerifyPasswordResponse',
              localId: 'XXXXX',
              email: 'john+doe@formbio.com',
              displayName: '',
              idToken: 'XXXXX',
              registered: true,
              refreshToken: 'XXXXX',
              expiresIn: '3600',
            }),
          );
        case ':lookup':
          return res(
            ctx.json({
              kind: 'identitytoolkit#GetAccountInfoResponse',
              users: [
                {
                  localId: 'XXXXX',
                  email: 'john+doe@formbio.com',
                  displayName: 'John Doe',
                  photoUrl: null,
                  passwordHash: 'XXXXXX',
                  emailVerified: true,
                  passwordUpdatedAt: 1657249439790,
                  providerUserInfo: [
                    {
                      providerId: 'password',
                      displayName: 'John Doe',
                      photoUrl: null,
                      email: 'john+doe@formbio.com',
                      rawId: 'john+doe@formbio.com',
                    },
                  ],
                  validSince: '1657249439',
                  lastLoginAt: '1662059571817',
                  createdAt: '1657222050338',
                  lastRefreshAt: '2022-09-02T05:08:42.473Z',
                },
              ],
            }),
          );
      }
    },
  ),
];
