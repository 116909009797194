import { SvgIcon, SvgIconProps } from '@mui/material';

export default function TrayIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='33' height='32' viewBox='0 0 33 32' {...props}>
      <path
        d='M5.5 20H10.0875C10.2174 19.9995 10.346 20.0247 10.4661 20.074C10.5862 20.1234 10.6955 20.1959 10.7875 20.2875L13.2125 22.7125C13.3045 22.8041 13.4138 22.8766 13.5339 22.926C13.654 22.9753 13.7826 23.0005 13.9125 23H19.0875C19.2174 23.0005 19.346 22.9753 19.4661 22.926C19.5862 22.8766 19.6955 22.8041 19.7875 22.7125L22.2125 20.2875C22.3045 20.1959 22.4138 20.1234 22.5339 20.074C22.654 20.0247 22.7826 19.9995 22.9125 20H27.5M6.5 5H26.5C27.0523 5 27.5 5.44772 27.5 6V26C27.5 26.5523 27.0523 27 26.5 27H6.5C5.94772 27 5.5 26.5523 5.5 26V6C5.5 5.44772 5.94772 5 6.5 5Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
