import { SvgIcon, SvgIconProps } from '@mui/material';

export default function LinkSimpleHorizontal(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071C9.48043 16.8946 9.73478 17 10 17H22C22.2652 17 22.5196 16.8946 22.7071 16.7071C22.8946 16.5196 23 16.2652 23 16C23 15.7348 22.8946 15.4804 22.7071 15.2929C22.5196 15.1054 22.2652 15 22 15H10C9.73478 15 9.48043 15.1054 9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16Z'
        fill='currentColor'
      />
      <path
        d='M13 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16C3 14.6739 3.52678 13.4021 4.46447 12.4645C5.40215 11.5268 6.67392 11 8 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H8C6.14348 9 4.36301 9.7375 3.05025 11.0503C1.7375 12.363 1 14.1435 1 16C1 17.8565 1.7375 19.637 3.05025 20.9497C4.36301 22.2625 6.14348 23 8 23H13C13.2652 23 13.5196 22.8946 13.7071 22.7071C13.8946 22.5196 14 22.2652 14 22C14 21.7348 13.8946 21.4804 13.7071 21.2929C13.5196 21.1054 13.2652 21 13 21Z'
        fill='currentColor'
      />
      <path
        d='M24 9H19C18.7348 9 18.4804 9.10536 18.2929 9.29289C18.1054 9.48043 18 9.73478 18 10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11H24C25.3261 11 26.5979 11.5268 27.5355 12.4645C28.4732 13.4021 29 14.6739 29 16C29 17.3261 28.4732 18.5979 27.5355 19.5355C26.5979 20.4732 25.3261 21 24 21H19C18.7348 21 18.4804 21.1054 18.2929 21.2929C18.1054 21.4804 18 21.7348 18 22C18 22.2652 18.1054 22.5196 18.2929 22.7071C18.4804 22.8946 18.7348 23 19 23H24C25.8565 23 27.637 22.2625 28.9497 20.9497C30.2625 19.637 31 17.8565 31 16C31 14.1435 30.2625 12.363 28.9497 11.0503C27.637 9.7375 25.8565 9 24 9Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
