import { DEFAULT_MARGIN } from '@/constants';
import { primary } from 'styles/themes/formbio/palette/primary';
import { CustomComponent } from 'styles/type-extension';

export const MuiTab: CustomComponent<'MuiTab'> = {
  styleOverrides: {
    root: {
      color: primary[600],
      padding: 0,
      minWidth: 0,
      marginLeft: DEFAULT_MARGIN,
      marginRight: DEFAULT_MARGIN,
      '&.Mui-selected': {
        color: primary[900],
      },
    },
  },
};

export const MuiTabs: CustomComponent<'MuiTabs'> = {
  styleOverrides: {
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: primary[900],
        borderRadius: '4px 4px 0px 0px',
      },
    },
  },
};
