import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Premium(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M2.81876 12.25C2.83475 12.3157 2.86376 12.3776 2.90406 12.4319C2.94436 12.4862 2.99515 12.5319 3.05341 12.5663C3.11167 12.6007 3.17623 12.623 3.24328 12.632C3.31032 12.641 3.37849 12.6365 3.44376 12.6187C6.42313 11.7938 9.57065 11.7938 12.55 12.6187C12.6153 12.6365 12.6834 12.641 12.7505 12.632C12.8175 12.623 12.8821 12.6007 12.9404 12.5663C12.9986 12.5319 13.0494 12.4862 13.0897 12.4319C13.13 12.3776 13.159 12.3157 13.175 12.25L14.7688 5.48123C14.7906 5.39063 14.7868 5.29572 14.7576 5.2072C14.7284 5.11869 14.675 5.0401 14.6035 4.9803C14.532 4.92051 14.4452 4.88188 14.3529 4.86879C14.2607 4.85571 14.1666 4.86867 14.0813 4.90623L10.9188 6.31248C10.8042 6.36293 10.675 6.36914 10.5561 6.32989C10.4372 6.29064 10.337 6.20873 10.275 6.09998L8.43751 2.78748C8.39427 2.70933 8.33089 2.64419 8.25395 2.59883C8.17701 2.55346 8.08933 2.52954 8.00001 2.52954C7.9107 2.52954 7.82301 2.55346 7.74607 2.59883C7.66913 2.64419 7.60575 2.70933 7.56251 2.78748L5.72501 6.09998C5.66298 6.20873 5.56285 6.29064 5.44396 6.32989C5.32506 6.36914 5.19585 6.36293 5.08126 6.31248L1.91876 4.90623C1.83346 4.86867 1.73935 4.85571 1.64707 4.86879C1.55479 4.88188 1.468 4.92051 1.39651 4.9803C1.32501 5.0401 1.27165 5.11869 1.24245 5.2072C1.21326 5.29572 1.20938 5.39063 1.23126 5.48123L2.81876 12.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
