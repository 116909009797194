import { CustomComponent } from 'styles/type-extension';
import { primary } from '../palette/primary';
import typography from '../typography';

const themeShadows12 =
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)';

export const MuiTooltip: CustomComponent<'MuiTooltip'> = {
  styleOverrides: {
    tooltip: {
      backgroundColor: primary[50],
      color: primary[900],
      boxShadow: themeShadows12,
      fontSize: typography.body2!.fontSize,
      lineHeight: typography.body2!.lineHeight,
      fontWeight: typography.body2!.fontWeight,
    },
  },
};
