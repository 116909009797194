import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ScissorsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M3.75 6.5C4.7165 6.5 5.5 5.7165 5.5 4.75C5.5 3.7835 4.7165 3 3.75 3C2.7835 3 2 3.7835 2 4.75C2 5.7165 2.7835 6.5 3.75 6.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M3.75 13C4.7165 13 5.5 12.2165 5.5 11.25C5.5 10.2835 4.7165 9.5 3.75 9.5C2.7835 9.5 2 10.2835 2 11.25C2 12.2165 2.7835 13 3.75 13Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.49998 8L5.19373 10.2625'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M14.5 3.8938L10.2687 6.78755'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M14.5 12.1063L5.19373 5.73755'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
