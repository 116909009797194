import { SvgIcon, SvgIconProps } from '@mui/material';

export default function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M29.5874 23.5L18.5999 4.49998C18.3369 4.04302 17.958 3.66346 17.5016 3.39953C17.0451 3.13561 16.5272 2.99664 15.9999 2.99664C15.4726 2.99664 14.9547 3.13561 14.4982 3.39953C14.0418 3.66346 13.6629 4.04302 13.3999 4.49998V4.49998L2.4124 23.5C2.14548 23.9546 2.00363 24.4718 2.00125 24.999C1.99887 25.5262 2.13604 26.0446 2.39884 26.5017C2.66163 26.9587 3.0407 27.3381 3.49753 27.6012C3.95436 27.8644 4.4727 28.002 4.9999 28H26.9999C27.5271 28.002 28.0454 27.8644 28.5023 27.6012C28.9591 27.3381 29.3382 26.9587 29.601 26.5017C29.8638 26.0446 30.0009 25.5262 29.9985 24.999C29.9962 24.4718 29.8543 23.9546 29.5874 23.5V23.5ZM14.9999 13C14.9999 12.7348 15.1053 12.4804 15.2928 12.2929C15.4803 12.1053 15.7347 12 15.9999 12C16.2651 12 16.5195 12.1053 16.707 12.2929C16.8945 12.4804 16.9999 12.7348 16.9999 13V18C16.9999 18.2652 16.8945 18.5196 16.707 18.7071C16.5195 18.8946 16.2651 19 15.9999 19C15.7347 19 15.4803 18.8946 15.2928 18.7071C15.1053 18.5196 14.9999 18.2652 14.9999 18V13ZM15.9999 24C15.7032 24 15.4132 23.912 15.1665 23.7472C14.9199 23.5824 14.7276 23.3481 14.6141 23.074C14.5005 22.7999 14.4708 22.4983 14.5287 22.2073C14.5866 21.9164 14.7295 21.6491 14.9392 21.4393C15.149 21.2295 15.4163 21.0867 15.7073 21.0288C15.9982 20.9709 16.2998 21.0006 16.5739 21.1142C16.848 21.2277 17.0823 21.42 17.2471 21.6666C17.4119 21.9133 17.4999 22.2033 17.4999 22.5C17.4999 22.8978 17.3419 23.2793 17.0606 23.5606C16.7793 23.8419 16.3977 24 15.9999 24Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
