import { CloseIcon } from '@/components/icons';
import AlertSnackbar, {
  AlertSnackbarProps,
} from '@/components/popups/AlertSnackbar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ConfirmationProps = {
  title: string;
  message: string;
  confirmButtonText: string;
  confirmButtonColor: 'primary' | 'error';
  confirmButtonIcon?: JSX.Element | undefined;
  optionalChild?: JSX.Element;
  onConfirm: () => Promise<void> | void;
};

interface NotificationContextValue {
  setSnackbar: Dispatch<SetStateAction<AlertSnackbarProps | null>>;
  setConfirmation: Dispatch<SetStateAction<ConfirmationProps | null>>;
}

const NotificationContext = createContext<NotificationContextValue>(
  {} as NotificationContextValue,
);

export function NotificationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [snackbar, setSnackbar] = useState<AlertSnackbarProps | null>(null);
  const [confirmation, setConfirmation] = useState<ConfirmationProps | null>(
    null,
  );

  return (
    <NotificationContext.Provider value={{ setSnackbar, setConfirmation }}>
      {children}
      {snackbar && (
        <AlertSnackbar
          {...snackbar}
          onClose={() => {
            snackbar.onClose && snackbar.onClose();
            setSnackbar(null);
          }}
        />
      )}
      {confirmation && (
        <Dialog
          open
          fullWidth
          maxWidth='sm'
          onClose={() => setConfirmation(null)}
        >
          <DialogTitle>
            {confirmation.title}
            <IconButton
              aria-label='close'
              onClick={() => setConfirmation(null)}
              sx={{ ml: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {confirmation.message}
            {confirmation.optionalChild && confirmation.optionalChild}
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              color='primary'
              onClick={() => setConfirmation(null)}
            >
              Close
            </Button>
            <Button
              variant='contained'
              startIcon={confirmation.confirmButtonIcon}
              color={confirmation.confirmButtonColor}
              type='button'
              onClick={() => {
                confirmation.onConfirm();
                setConfirmation(null);
              }}
            >
              {confirmation.confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export default NotificationContext;
