import { CustomComponent } from 'styles/type-extension';
import { primary } from '../palette/primary';
import { info } from '../palette/info';

export const MuiTextField: CustomComponent<'MuiTextField'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        '& .MuiInputBase-input': {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        },
        '& .MuiInputBase-inputMultiline': {
          padding: 0,
        },
        '& fieldset': {
          borderColor: primary[400],
        },
        '&:hover fieldset': {
          borderColor: primary[500],
        },
        '&.Mui-focused fieldset': {
          borderWidth: 1,
          borderColor: primary[900],
          boxShadow: `0px 0px 0px 4px ${info[300]}`,
        },
      },

      '& .MuiFormHelperText-root': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    }),
  },
};
