import { PaletteOptions } from '@mui/material';
import { error } from './error';
import { info } from './info';
import { neutral } from './neutral';
import { primary } from './primary';
import { secondary } from './secondary';
import { success } from './success';
import { tertiary } from './tertiary';
import { warning } from './warning';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    ...primary,
    main: primary[900],
    contrastText: primary[50],
  },
  text: {
    primary: primary[900],
  },
  secondary: {
    ...secondary,
    main: secondary[500],
  },
  neutral,
  info: {
    ...info,
    main: info[500],
  },
  success: {
    ...success,
    main: success[500],
  },
  warning: {
    ...warning,
    main: warning[500],
  },
  error: {
    ...error,
    main: error[500],
  },
  ...tertiary,
  navItemSelectedTextColor: primary[50],
  navItemDefaultTextColor: primary[400],
  navIconSelectedColor: secondary[500],
  folderIconEnabled: primary[500],
  folderIconSelected: primary[900],
  folderTextEnabled: primary[700],
  folderTextSelected: primary[900],
  folderHover: primary[100],
  folderSelected: primary[200],
  itemSelectedBackground: info[100],
  tertiaryColors: Object.values(tertiary),
};

export default palette;

export const paletteDark: PaletteOptions = {
  mode: 'dark',
  primary: {
    ...primary,
    main: primary[900],
    contrastText: primary[50],
  },
  text: {
    primary: primary[50],
  },
  secondary: {
    ...secondary,
    main: secondary[500],
  },
  neutral,
  success: {
    ...success,
    main: success[500],
  },
  error: {
    ...error,
    main: error[400],
  },
};
