/**
 * This file represents ALL routes that the UI navigates to.
 * If you want to build a link or router.push (use links when possible),
 * use navRoutes.get("MY_NAV_ROUTE_ID")
 *
 * The helper function getHref will generate a proper href to pass to RouterLink
 * from the current route.
 */
import { NextRouter } from 'next/router';
import { UrlObject } from 'url';
import { getQueryParams } from './api-helpers';

export enum QueryParams {
  orgId = 'orgId',
  projId = 'pid',
  runId = 'runId',
  groupKey = 'groupKey',
  email = 'email',
  mode = 'mode',
  oobCode = 'oobCode',
  // TODO: Remove runName after creating step-based workflow builder
  runName = 'runName',
  // for search box queries
  search = 'search',
  // for keeping track of which tab is selected
  tab = 'tab',
  // for searching users
  users = 'users',
  // path for file management directory
  path = 'path',
  // destination page after login
  destination = 'destination',
  // workflow category picked by user
  category = 'category',
  status = 'status',
  previousPageParams = 'previousPageParams',
  // name of repo for a workflow
  repoName = 'repoName',
  workflowName = 'workflowName',
  // workflow version
  version = 'version',
  // uploaded org and proj indicate those to which a workflow is uploaded to
  uploadedOrg = 'uploadedOrg',
  uploadedProj = 'uploadedProj',
  toolSlug = 'toolSlug',
  toolRunId = 'toolRunId',
}

// The most common combination of query params
const orgAndProj: QueryParams[] = [QueryParams.orgId, QueryParams.projId];

const ORGANIZATIONS_SCOPED_PATHNAME = `/organizations`;
const ORGANIZATION_SCOPED_PATHNAME =
  ORGANIZATIONS_SCOPED_PATHNAME + `/[${QueryParams.orgId}]`;
const PROJECTS_SCOPED_PATHNAME = ORGANIZATION_SCOPED_PATHNAME + '/projects';
const PROJECT_SCOPED_PATHNAME =
  PROJECTS_SCOPED_PATHNAME + `/[${QueryParams.projId}]`;
const WORKFLOW_RUNS_SCOPED_PATHNAME =
  PROJECT_SCOPED_PATHNAME + '/workflow-runs';
const WORKFLOWS_PATHNAME = PROJECT_SCOPED_PATHNAME + '/workflows';
const TOOLBOX_PATHNAME = PROJECT_SCOPED_PATHNAME + '/toolbox';
const TOOL_RUN_PATHNAME = PROJECT_SCOPED_PATHNAME + '/tool-runs';
const MANAGEMENT_SCOPED_PATHNAME = `/management`;
const MANAGEMENT_ORG_SCOPED_PATHNAME =
  MANAGEMENT_SCOPED_PATHNAME + `/[${QueryParams.orgId}]/projects`;
const MANAGEMENT_ORG_MEMBERS_SCOPED_PATHNAME =
  MANAGEMENT_SCOPED_PATHNAME + `/[${QueryParams.orgId}]/members`;
const MY_PROFILE_SCOPE_PATHNAME = '/profile';

export interface NavRoute {
  pathname: string;
  queryKeys?: QueryParams[];
}

export type Breadcrumb = {
  id: string;
  name: string;
  href?: UrlObject;
};

const home: NavRoute = {
  pathname: '/',
};

const login: NavRoute = {
  pathname: '/auth/login',
  queryKeys: [QueryParams.destination],
};

const forgotPassword: NavRoute = {
  pathname: '/auth/forgot-password',
};

const createAccount: NavRoute = {
  pathname: '/auth/create-account',
};

const resetPassword: NavRoute = {
  pathname: '/auth/reset-password',
};

const project: NavRoute = {
  pathname: PROJECT_SCOPED_PATHNAME,
  queryKeys: orgAndProj,
};

const projectHome: NavRoute = {
  pathname: PROJECT_SCOPED_PATHNAME + '/home',
  queryKeys: orgAndProj,
};

const projectMembers: NavRoute = {
  pathname: PROJECT_SCOPED_PATHNAME + '/members',
  queryKeys: orgAndProj,
};

const workflowRuns: NavRoute = {
  pathname: WORKFLOW_RUNS_SCOPED_PATHNAME,
  queryKeys: [
    ...orgAndProj,
    QueryParams.status,
    QueryParams.users,
    QueryParams.search,
  ],
};

const workflowRun: NavRoute = {
  pathname: WORKFLOW_RUNS_SCOPED_PATHNAME + `/[${QueryParams.runId}]`,
  queryKeys: [...orgAndProj, QueryParams.runId, QueryParams.previousPageParams],
};

const workflowsList: NavRoute = {
  pathname: WORKFLOWS_PATHNAME,
  queryKeys: [...orgAndProj, QueryParams.category],
};

const workflowDocs: NavRoute = {
  pathname:
    WORKFLOWS_PATHNAME +
    `/[${QueryParams.repoName}]/[${QueryParams.workflowName}]`,
  queryKeys: [
    ...orgAndProj,
    QueryParams.repoName,
    QueryParams.workflowName,
    QueryParams.category,
    QueryParams.tab,
    QueryParams.version,
    QueryParams.uploadedOrg,
    QueryParams.uploadedProj,
  ],
};

const workflowBuilder: NavRoute = {
  pathname:
    WORKFLOWS_PATHNAME +
    `/[${QueryParams.repoName}]/[${QueryParams.workflowName}]/[${QueryParams.groupKey}]`,
  queryKeys: [
    ...orgAndProj,
    QueryParams.repoName,
    QueryParams.workflowName,
    QueryParams.groupKey,
    QueryParams.version,
    QueryParams.uploadedOrg,
    QueryParams.uploadedProj,
  ],
};

const toolbox: NavRoute = {
  pathname: TOOLBOX_PATHNAME,
  queryKeys: orgAndProj,
};

const toolboxBuilder: NavRoute = {
  pathname: `${TOOLBOX_PATHNAME}/[${QueryParams.toolSlug}]`,
  queryKeys: [...orgAndProj, QueryParams.toolSlug, QueryParams.version],
};

const toolRuns: NavRoute = {
  pathname: TOOL_RUN_PATHNAME,
  queryKeys: [...orgAndProj],
};

const toolRun: NavRoute = {
  pathname: `${TOOL_RUN_PATHNAME}/[${QueryParams.toolRunId}]`,
  queryKeys: [...orgAndProj, QueryParams.toolRunId],
};

const vault: NavRoute = {
  pathname: PROJECT_SCOPED_PATHNAME + '/vault',
  queryKeys: [...orgAndProj, QueryParams.path, QueryParams.search],
};

const checkVerifyEmail: NavRoute = {
  pathname: '/auth/check-verify-email',
  queryKeys: [QueryParams.email],
};

const verifyEmail: NavRoute = {
  pathname: '/auth/verify-email',
  queryKeys: [QueryParams.email],
};

const organizationRoute: NavRoute = {
  pathname: ORGANIZATION_SCOPED_PATHNAME,
  queryKeys: [QueryParams.orgId],
};

const organizationsRoute: NavRoute = {
  pathname: '/organizations',
};

const managementRoute: NavRoute = {
  pathname: MANAGEMENT_SCOPED_PATHNAME,
};

const organizationManagementRoute: NavRoute = {
  pathname: MANAGEMENT_ORG_SCOPED_PATHNAME,
  queryKeys: [QueryParams.orgId],
};

const membersManagementRoute: NavRoute = {
  pathname: MANAGEMENT_ORG_MEMBERS_SCOPED_PATHNAME,
  queryKeys: [QueryParams.orgId],
};

const myProfileRoute: NavRoute = {
  pathname: MY_PROFILE_SCOPE_PATHNAME,
};

const refreshTokenRoute: NavRoute = {
  pathname: '/refresh-token',
};

/**
 * Utils function to create a href (with router navigation)
 * by retrieving the route params from the router
 * @param navRoute one of the routes described in this file
 * @param router NextRouter
 * @param params key-value pairs to add to path as query or path params
 * @returns
 */
//  TODO: Refactor to require organization for all routes (must also refactor all routes)
export function getHref(
  router: NextRouter,
  navRoute: NavRoute,
  params: { [key in QueryParams]?: string } = {},
): UrlObject {
  if (!navRoute.queryKeys || !navRoute.queryKeys.length) {
    return { pathname: navRoute.pathname };
  } else {
    const query: { [key: string]: string } = {};

    navRoute.queryKeys.forEach(key => {
      if (params[key] !== undefined) {
        // @ts-expect-error (was seeing error that query[key] might be undefined despite conditional)
        query[key] = params[key];
      } else if (key !== QueryParams.search) {
        query[key] = getQueryParams(router, key);
      }
    });

    return { pathname: navRoute.pathname, query };
  }
}

function isSubPath(currentPathname: string, toTestPathname: string) {
  const parentPathname = currentPathname.split('/').slice(0, -1).join('/');
  return parentPathname === toTestPathname;
}

/**
 * Finds if the { NavItem } pathname matches the current path
 * or if it matches one level up.
 * @param currentPathname pathname from the router
 * @param navItemPathname pathname from the current nav item
 * @returns true if the navitem should be selected
 */
export function isNavItemInRoute(
  currentPathname: string,
  navItemPathname: string,
): boolean {
  return (
    currentPathname === navItemPathname ||
    isSubPath(currentPathname, navItemPathname)
  );
}

/**
 * Checks if a route is dependent on having a project id.
 * @param queryKeys the list of query params needed for a given route
 * @returns true if the route depends on a project
 */
export function dependsOnProject(queryKeys: QueryParams[] | undefined) {
  return queryKeys?.includes(QueryParams.projId);
}

export function updateRouterQuery(
  router: NextRouter,
  paramName: QueryParams,
  value: string | string[],
) {
  const newQuery = { ...router.query };
  newQuery[paramName] = value;
  router.query = newQuery;
  router.push(router);
}

export const inManagementPath = (pathname?: string) => {
  if (!pathname) {
    return false;
  }
  return pathname.startsWith(navRoutes.management.pathname);
};

export const navRoutes = {
  home,
  login,
  forgotPassword,
  createAccount,
  project,
  projectHome,
  projectMembers,
  workflowRuns,
  workflowRun,
  workflowsList,
  workflowBuilder,
  toolbox,
  toolboxBuilder,
  toolRuns,
  toolRun,
  resetPassword,
  vault,
  organization: organizationRoute,
  organizations: organizationsRoute,
  checkVerifyEmail,
  verifyEmail,
  workflowDocs,
  management: managementRoute,
  managementOrg: organizationManagementRoute,
  managementMembers: membersManagementRoute,
  myProfile: myProfileRoute,
  refreshToken: refreshTokenRoute,
};
