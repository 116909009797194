import { DEFAULT_BORDER_RADIUS } from '@/constants';
import { CustomComponent } from 'styles/type-extension';

export const MuiIconButton: CustomComponent<'MuiIconButton'> = {
  variants: [
    {
      props: {},
      style: () => ({
        borderRadius: DEFAULT_BORDER_RADIUS,
      }),
    },
  ],
};
