import { SvgIcon, SvgIconProps } from '@mui/material';

export default function InfinityHorizontalIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M6.66256 9.50623L6.11881 10.1187C5.69992 10.5369 5.16647 10.8215 4.58589 10.9366C4.0053 11.0517 3.40362 10.9921 2.85689 10.7653C2.31016 10.5386 1.84291 10.1549 1.51418 9.66267C1.18546 9.17047 1.01001 8.59186 1.01001 7.99998C1.01001 7.40809 1.18546 6.82949 1.51418 6.33728C1.84291 5.84508 2.31016 5.46136 2.85689 5.23461C3.40362 5.00787 4.0053 4.94828 4.58589 5.06337C5.16647 5.17846 5.69992 5.46307 6.11881 5.88123L9.88131 10.1187C10.3002 10.5369 10.8336 10.8215 11.4142 10.9366C11.9948 11.0517 12.5965 10.9921 13.1432 10.7653C13.69 10.5386 14.1572 10.1549 14.4859 9.66267C14.8147 9.17047 14.9901 8.59186 14.9901 7.99998C14.9901 7.40809 14.8147 6.82949 14.4859 6.33728C14.1572 5.84508 13.69 5.46136 13.1432 5.23461C12.5965 5.00787 11.9948 4.94828 11.4142 5.06337C10.8336 5.17846 10.3002 5.46307 9.88131 5.88123L9.33756 6.49373'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
