import { NextApiRequest } from 'next';
import { NextRouter } from 'next/dist/client/router';

export function getQueryParams(
  routerReq: NextRouter | NextApiRequest,
  param: string,
): string {
  const queryParam = routerReq.query[param];
  if (!queryParam) {
    return '';
  }
  if (Array.isArray(queryParam)) {
    return queryParam[queryParam.length - 1];
  } else {
    return String(queryParam);
  }
}

export function getAllQueryParams(routerReq: NextRouter | NextApiRequest): {
  [key: string]: string;
} {
  const params: { [key: string]: string } = {};
  Object.entries(routerReq.query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params[key] = value[0];
    } else {
      params[key] = value;
    }
  });

  return params;
}
