import type { Organization } from '../../types/organization';
import type { Role } from '../../types/member';
import { api } from '../../utils/api';

export interface CreateData {
  name: string;
}

export interface MemberData {
  email: string;
  role: Role;
}

export function fetchAll() {
  return api.get<Organization[]>('/user/orgs').then(({ data }) => data);
}

export function fetchOne(oid: string) {
  return api.get<Organization>(`/orgs/${oid}`).then(({ data }) => data);
}

export function create(data: CreateData) {
  return api.post(`/organizations`, data);
}

export function remove(oid: string) {
  return api.delete(`/orgs/${oid}`);
}

export function removeMember(oid: string, email: string, params?: { deleteProjectMembership?: boolean }) {
  return api.delete(`/orgs/${oid}/members`, { params, data: { userEmail: email } });
}

export function addMember(oid: string, data: MemberData) {
  return api.post(`/orgs/${oid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}

export function addMembers(oid: string, data: MemberData[]) {
  return api.post(
    `/orgs/${oid}/members/bulk`,
    data.map((m) => {
      return {
        userEmail: m.email,
        role: m.role,
      };
    }),
  );
}

export function updateMember(oid: string, data: MemberData) {
  return api.patch(`/orgs/${oid}/members`, {
    userEmail: data.email,
    role: data.role,
  });
}
