import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ArrowsInLineHorizontal(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
      <path
        d='M17 5V27C17 27.2652 16.8946 27.5196 16.7071 27.7071C16.5196 27.8946 16.2652 28 16 28C15.7348 28 15.4804 27.8946 15.2929 27.7071C15.1054 27.5196 15 27.2652 15 27V5C15 4.73478 15.1054 4.48043 15.2929 4.29289C15.4804 4.10536 15.7348 4 16 4C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5ZM8.7125 11.2875C8.56791 11.1518 8.38787 11.0599 8.19321 11.0223C7.99854 10.9847 7.79721 11.003 7.6125 11.075C7.4305 11.1515 7.27521 11.2802 7.16622 11.4448C7.05722 11.6094 6.99938 11.8026 7 12V15H2C1.73478 15 1.48043 15.1054 1.29289 15.2929C1.10536 15.4804 1 15.7348 1 16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17H7V20C6.99938 20.1974 7.05722 20.3906 7.16622 20.5552C7.27521 20.7198 7.4305 20.8485 7.6125 20.925C7.73585 20.974 7.86728 20.9994 8 21C8.26554 20.9989 8.52054 20.896 8.7125 20.7125L12.7125 16.7125C12.9003 16.5229 13.0056 16.2668 13.0056 16C13.0056 15.7332 12.9003 15.4771 12.7125 15.2875L8.7125 11.2875ZM30 15H25V12C25.0006 11.8026 24.9428 11.6094 24.8338 11.4448C24.7248 11.2802 24.5695 11.1515 24.3875 11.075C24.2028 11.003 24.0015 10.9847 23.8068 11.0223C23.6121 11.0599 23.4321 11.1518 23.2875 11.2875L19.2875 15.2875C19.0997 15.4771 18.9944 15.7332 18.9944 16C18.9944 16.2668 19.0997 16.5229 19.2875 16.7125L23.2875 20.7125C23.4795 20.896 23.7345 20.9989 24 21C24.1327 20.9994 24.2642 20.974 24.3875 20.925C24.5695 20.8485 24.7248 20.7198 24.8338 20.5552C24.9428 20.3906 25.0006 20.1974 25 20V17H30C30.2652 17 30.5196 16.8946 30.7071 16.7071C30.8946 16.5196 31 16.2652 31 16C31 15.7348 30.8946 15.4804 30.7071 15.2929C30.5196 15.1054 30.2652 15 30 15Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
