import { SvgIcon, SvgIconProps } from '@mui/material';

export default function PlayOutlined(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        d='M14.2563 7.57494L5.2625 2.07494C5.18679 2.02824 5.09999 2.00258 5.01106 2.00061C4.92212 1.99864 4.83427 2.02044 4.75657 2.06375C4.67887 2.10706 4.61413 2.17031 4.56903 2.24699C4.52393 2.32366 4.5001 2.41098 4.5 2.49994V13.4999C4.5001 13.5889 4.52393 13.6762 4.56903 13.7529C4.61413 13.8296 4.67887 13.8928 4.75657 13.9361C4.83427 13.9794 4.92212 14.0012 5.01106 13.9993C5.09999 13.9973 5.18679 13.9716 5.2625 13.9249L14.2563 8.42494C14.3301 8.38127 14.3913 8.3191 14.4338 8.24458C14.4763 8.17005 14.4987 8.08574 14.4987 7.99994C14.4987 7.91414 14.4763 7.82982 14.4338 7.7553C14.3913 7.68077 14.3301 7.61861 14.2563 7.57494V7.57494Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
